import Auth from "@/router/middleware/Auth";
import RoleGuard from "@/router/middleware/RoleGuard";
import {UserRoleEnum} from "@/store/enums/UserRoleEnum";

const gymstreetRoutes = [
  {
    path: "/gymstreet/uploads",
    name: "/gymstreet/uploads",
    component: () => import("@/pages/gymstreet/uploads/Uploads.vue")
  },
  {
    path: "/gymstreet/payments",
    name: "/gymstreet/payments",
    component: () => import("@/pages/gymstreet/payments/Payments.vue")
  },
  {
    path: "/gymstreet/users",
    name: "/gymstreet/users",
    component: () => import("@/pages/gymstreet/users/Users.vue")
  },
  {
    path: "/gymstreet/card-users",
    name: "/gymstreet/card-users",
    component: () => import("@/pages/gymstreet/users/CardUsers.vue"),
    beforeEnter: [ RoleGuard([UserRoleEnum.ADMIN,UserRoleEnum.SUPERADMIN]) ]
  },

  {
    path: "/gymstreet/analytics",
    name: "/gymstreet/analytics",
    component: () => import("@/pages/gymstreet/users/Analytics.vue"),
    beforeEnter: [ RoleGuard([UserRoleEnum.SUPERADMIN]) ]
  },
  {
    path: "/gymstreet/per-user",
    name: "/gymstreet/per.user",
    component: () => import("@/pages/gymstreet/users/PerUser.vue"),
    beforeEnter: [ RoleGuard([UserRoleEnum.SUPERADMIN]) ]
  },
  {
    path: "/gymstreet/cash-promo",
    name: "/gymstreet/cash.promo",
    component: () => import("@/pages/gymstreet/users/CashPromo.vue"),
    beforeEnter: [ RoleGuard([UserRoleEnum.SUPERADMIN]) ]
  },
  {
    path: "/gymstreet/pap-statistics",
    name: "/gymstreet/pap.statistics",
    component: () => import("@/pages/gymstreet/users/PapStatistics.vue"),
    beforeEnter: [ RoleGuard([UserRoleEnum.SUPERADMIN]) ]
  },
  {
    path: "/gymstreet/maintenances",
    name: "/gymstreet/maintenances",
    component: () => import("@/pages/gymstreet/maintenances/Maintenances.vue"),
    beforeEnter: [ RoleGuard([UserRoleEnum.SUPERADMIN]) ]
  },
  {
    path: "/gymstreet/elite-vote",
    name: "/gymstreet/elite-vote",
    component: () => import("@/pages/gymstreet/elite-vote/Campaigns.vue"),
  },
  {
    path: "/gymstreet/elite-vote/new",
    name: "/gymstreet/elite-vote/new",
    component: () => import("@/pages/gymstreet/elite-vote/EditCampaign.vue"),
  },
  {
    path: "/gymstreet/elite-vote/edit/:id",
    name: "/gymstreet/elite-vote/edit",
    props: true,
    component: () => import("@/pages/gymstreet/elite-vote/EditCampaign.vue"),
  },
  {
    path: "/statistics/products",
    name: "/statistics/products",
    props: true,
    component: () => import("@/pages/statistics/Products/index.vue"),
  },
  {
    path: "/statistics/filecoin",
    name: "/statistics/filecoin",
    props: true,
    component: () => import("@/pages/statistics/Filecoin/index.vue"),
  },
  {
    path: "/statistics/btc",
    name: "/statistics/btc",
    props: true,
    component: () => import("@/pages/statistics/Btc/index.vue"),
  },
  {
    path: "/statistics/btc-by-time",
    name: "/statistics/btc-by-time",
    props: true,
    component: () => import("@/pages/statistics/Btc/btc.vue"),
  },
  {
    path: "/statistics/moonberg",
    name: "/statistics/moonberg",
    props: true,
    component: () => import("@/pages/statistics/Moonberg/index.vue"),
  },
];

gymstreetRoutes.forEach((route: any) => {
  const roleGuards = route.beforeEnter && route.beforeEnter.length ? route.beforeEnter : []
  route['beforeEnter'] = [ Auth, ...roleGuards  ]
});

export default gymstreetRoutes;
