<template>
  <el-dialog
    v-model="isVisible"
    width="500px"
    align-center
    @close="handleOnClose"
    :align-center="true"
  >
    <div class="text-center">
      <el-form
          ref="formRef"
          :model="addAutoshipFrom"
          label-width="60px"
          class="demo-form-inline"
      >
        <el-form-item :label="$t('Wallet')">
          <el-input v-model="addAutoshipFrom.walletAddress" disabled />
        </el-form-item>
        <el-form-item
            :label="$t('Days')"
            :rules="[
                { required: true, message: 'Days is required' },
                { type: 'integer', message: 'Days must be a number' },
                { min: 1, message: 'Min number should be 1' },
            ]"
        >
          <el-input clearable v-model="addAutoshipFrom.days" :placeholder="$t('Days')" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
        <span class="dialog-footer">
          <el-button
              class="btn btn-outline-secondary text-gray-600"
              @click="closePopup"
          >
            {{ $t('Close') }}
          </el-button>
          <el-button
              class="btn btn-primary text-white"
              v-loading="addAutoshipLoad"
              @click="handleAddAutoship"
          >
            {{ $t('Add autoship') }}
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>
<script>
import store from "../../../store";
import NotificationService from "../../../core/services/NotificationService";

export default {
  name: "UserAddAutoship",
  props: {
    popupVisible: Boolean,
    addAutoshipFrom: Object
  },
  emits: [ "handle-on-close" ],
  data() {
    return {
      initialEmail: '',
      isVisible: true,
      addAutoshipLoad: false,
    }
  },
  methods: {
    closePopup() {
      this.isVisible = false
    },
    handleOnClose() {
      this.$emit("handle-on-close");
    },
    async handleAddAutoship(){
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          this.addAutoshipLoad = true
          try {
            const { walletAddress, days } = this.addAutoshipFrom
            await store.dispatch("binaryUserAddAutoship", {
              walletAddress,
              days
            });

            NotificationService.swalNotify({ title: this.$t('Autoship has added successfully')})
          } catch (err) {
            NotificationService.swalError(this.$t('User add autoship error'))
            console.error(err)
          } finally {
            this.addAutoshipLoad = false
          }
        } else {
          return false
        }
      })
    },
  },
};
</script>
