<template>
  <div>
    <apexchart type="pie" width="700" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script setup>
  import {computed} from "vue";
  import {PRODUCTS} from "@/core/config/constants";

  const props = defineProps({
    products: Array,
  })

  const getProductAmount = (productId) => {
    const [product] = props.products.filter(product => Number(product.productId) === productId)
    return product ? Number(product.totalQuantity.toFixed(2)) : 0
  }

  const series = computed(() =>Object.keys(PRODUCTS).map(productId => getProductAmount(Number(productId))))

  console.log(series);
  const chartOptions= {
    chart: {
      width: 600,
      type: 'pie',
    },
    labels: Object.values(PRODUCTS),
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  }
</script>

<style scoped lang="scss">

</style>
