import Swal, { SweetAlertCustomClass } from "sweetalert2";

export class SweetAlertService {
  static notificationTypes: any = {
    success: 'success',
    error: 'error',
    warning: 'warning',
  }

  static async showImage(imageUrl: string,
                         title:string = '',
                         description: string = '',
                         width: number = 400,
                         height: number = 200,
                         customClass: SweetAlertCustomClass | undefined = undefined): Promise<void> {
    await Swal.fire({
      title: title,
      text: description,
      imageUrl: imageUrl,
      imageWidth: width,
      imageHeight: height,
      imageAlt: title,
      confirmButtonText: 'Close',
      customClass,
    });
  }

  static notify(type: string = SweetAlertService.notificationTypes.success, title: string = ''): void {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false
    })

    // @ts-ignore
    Toast.fire({
      icon: type,
      title: title
    })
  }

  static async swalMessageBox(): Promise<any> {
    return Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Type your message here...',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      showCancelButton: true
    })
  }
}
