<template>
  <div class="page-content">
    <div>
      <UploadsFilter :uploadsFilterForm="uploadsFilterForm" @onFilterUploads="handleFilterUploads" />
    </div>
    <div><hr></div>
    <div v-if="uploadsContainer">
      <el-table :data="uploadsContainer.getUploads()" style="width: 100%" class="mt-5 mb-5">
        <el-table-column prop="url" :label="$t('Image')" width="80">
          <template #default="scope">
            <el-avatar class="pointer" :size="50" :src="scope.row.url" @click="showImage(scope.row.url)" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('Uploaded By')" width="280" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row.uploadedBy.wallet_address }}
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('Uploaded At')" width="260" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row.uploadedAt.toUTCString() }}
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('Updated At')" width="260" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row.uploadedAt.toUTCString() }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('Status')" width="180">
          <template #default="scope">
            <StatusInfo
              :text="scope.row.statusInfo.statusText"
              :className="scope.row.statusInfo.statusClassName"
            />
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template #default="scope">
            <div>
              <CustomLoader v-if="pendingActionsIndexes.has(scope.$index)" :textVisible="false" />
              <div v-else class="me-0 d-flex justify-content-end pe-2">
                <el-dropdown trigger="click" class="mr-2" @command="handleUploadCommand">
                  <span class="el-dropdown-link bank-teams-table--link-icon">
                    <i class="bi bi-three-dots fs-1 fw-bolder"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item v-if="scope.row.status !== UploadStatusEnum.APPROVED"
                                        :command="{action: UPLOAD_ACTIONS.APPROVE, imageId: scope.row.id, rowIndex: scope.$index}">
                        {{ $t(("Approve")) }}
                      </el-dropdown-item>
                      <el-dropdown-item v-if="scope.row.status !== UploadStatusEnum.REJECTED"
                        :command="{action: UPLOAD_ACTIONS.REJECT, imageId: scope.row.id, rowIndex: scope.$index}">
                        {{ $t(("Reject")) }}
                      </el-dropdown-item>
                      <el-dropdown-item v-if="scope.row.status !== UploadStatusEnum.REJECTED"
                        :command="{action: UPLOAD_ACTIONS.REJECT_WITH_MESSAGE, imageId: scope.row.id, rowIndex: scope.$index}">
                        {{ $t(("Reject with message")) }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-if="pagination"
        :pagination="pagination"
        @onSizeChange="handleSizeChange"
        @onCurrentChange="handleCurrentChange"
      />
    </div>
    <div v-else>
      <CustomLoader />
    </div>
  </div>
</template>

<script>

import store from "@/store";
import CustomLoader from "@/shared-components/utils/CustomLoader";
import { UploadStatusEnum } from "@/store/enums/UploadStatusEnum";
import { SweetAlertService } from "@/core/services/SweetAlertService";
import { ERROR_MESSAGES } from "@/core/config/constants";
import StatusInfo from "@/shared-components/utils/StatusInfo";
import UploadsFilter from "@/pages/gymstreet/uploads/UploadsFilter";
import UploadContainer from "@/store/models/UploadContainer";
import Pagination from "@/shared-components/utils/Pagination";
import { PaginationModel } from "@/store/models/utils/PaginationModel";

export default {
  name: "Uploads",
  components: { Pagination, UploadsFilter, StatusInfo, CustomLoader },
  data() {
    return {
      uploadsContainer: null,
      pagination: {
        page: '',
        per_page: '',
      },
      uploadsFilterForm: {
        wallet_address: '',
        statuses: []
      },
      UPLOAD_ACTIONS: {
        APPROVE: 1,
        REJECT: 2,
        REJECT_WITH_MESSAGE: 3
      },
      pendingActionsIndexes: new Set(),
      UploadStatusEnum
    };
  },
  async mounted() {
    this.handleQuery()
    await this.fetchUploads()
  },
  methods: {
    async handleUploadCommand(command) {
      this.pendingActionsIndexes.add(command.rowIndex);
      try {
        switch (command.action) {
          case this.UPLOAD_ACTIONS.APPROVE:
            await this.handleApproveImage(command.imageId);
            SweetAlertService.notify(SweetAlertService.notificationTypes.success, "Approved");
            break;
          case this.UPLOAD_ACTIONS.REJECT:
            await this.handleRejectImage(command.imageId);
            SweetAlertService.notify(SweetAlertService.notificationTypes.success, "Rejected");
            break;
          case this.UPLOAD_ACTIONS.REJECT_WITH_MESSAGE:
            await this.handleRejectImageWithMessage(command.imageId);
            break;
        }
      } catch (e) {
        console.error(e);
        SweetAlertService.notify(SweetAlertService.notificationTypes.error, ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }
      this.pendingActionsIndexes.delete(command.rowIndex);
    },
    async handleApproveImage(imageId) {
      let res = await store.dispatch("approveImage", { image_id: imageId });
      if (res) {
        this.uploadsContainer.approveImage(imageId);
      }
    },
    async handleRejectImage(imageId) {
      let res = await store.dispatch("rejectImage", { image_id: imageId });
      if (res) {
        this.uploadsContainer.rejectImage(imageId);
      }
    },
    async handleRejectImageWithMessage(imageId) {
      let res = await SweetAlertService.swalMessageBox();
      if (res.isDismissed) {
        SweetAlertService.notify(SweetAlertService.notificationTypes.success, "Canceled");
      } else {
        let payload = { image_id: imageId };
        if (res.value) {
          payload.reason = res.value;
        }
        let rejectRes = await store.dispatch("rejectImage", payload);
        if (rejectRes) {
          this.uploadsContainer.rejectImage(imageId);
          SweetAlertService.notify(SweetAlertService.notificationTypes.success, "Rejected");
        }
      }
    },
    showImage(imageUrl) {
      SweetAlertService.showImage(imageUrl, undefined, undefined, null, null, {
        image: 'uploaded-image-preview',
        popup: 'image-preview-popup',
      });
    },
    async fetchUploads() {
      this.uploadsContainer = null;
      try {
        let uploadsRes = await store.dispatch("fetchUploads", { filters: { ...this.uploadsFilterForm }, pagination: { ...this.pagination } });
        this.uploadsContainer = new UploadContainer(uploadsRes.uploads);
        this.pagination = new PaginationModel(uploadsRes.pagination);
      } catch (e) {
        console.error(e);
        SweetAlertService.notify(SweetAlertService.notificationTypes.error, ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }
    },
    async handleFilterUploads() {
      this.pagination.page = 1;
      await this.fetchUploads()
    },
    async handleSizeChange(pageSize) {
      this.pagination.per_page = pageSize;
      await this.fetchUploads();
    },
    async handleCurrentChange(currentPage) {
      this.pagination.page = currentPage;
      await this.fetchUploads();
    },
    handleQuery(){
      const query = this.$route.query
      this.pagination.page = query.page || ''
      this.pagination.per_page = query.per_page || ''
      this.uploadsFilterForm.wallet_address = query.wallet_address || ''
      this.uploadsFilterForm.statuses =
          query.statuses
              ? Array.isArray(query.statuses)
                ? query.statuses.map(item => parseInt(item))
                : [parseInt(query.statuses)]
              : []
    }
  }
};
</script>

<style>
.uploaded-image-preview {
  max-width: 400px;
  max-height: 400px;
}

.image-preview-popup {
  width: unset;
  max-width: unset;
}
</style>

<style scoped>

</style>
