import i18n from "@/core/plugins/i18n";
import { PlatformEnum } from "@/store/enums/PlatformEnum";

export const arrayMerge = (arr1: any[], arr2: any[]): any[] => {
  return arr1.concat(arr2);
};

export const translateGlobal = (key: String): string => {
  return i18n.global.t(key);
};

export const getPlatform = (): string | null => {
  let OS = null as string | null;
  let agent = navigator.userAgent;

  if (agent.includes("Win")) OS = PlatformEnum.WEB;
  if (agent.includes("Mac")) OS = PlatformEnum.WEB;
  if (agent.includes("Linux")) OS = PlatformEnum.WEB;
  if (agent.includes("Android")) OS = PlatformEnum.ANDROID;
  if (agent.includes("iPhone")) OS = PlatformEnum.IOS;

  return OS;
};

export const createAndOpenLink = (link: string, target: string | null = null): void => {
  const a = document.createElement("a");
  a.href = link;

  if (target) a.target = target;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const getHidedEmail = (email: string) => {
  const firstElement = email.split("@")[0];
  const secondElement = email.split("@")[1];

  if (firstElement.length > 1 && firstElement.length < 3) {
    return email;
  }
  const firstElementChanged = firstElement
    .split("")
    .map((el, index) => (index > 1 ? "*" : el))
    .join("");

  return [firstElementChanged, secondElement].join("@");
};

export const getStatusClass = (value) => {
  if (value < 2.99) {
    return 'status-danger'
  } else if( value >= 3  && value  < 3.99) {
    return 'status-warning'
  } else if (value >= 4) {
    return 'status-success'
  }
}

export const getOrdered = (data: any[], byKey: string,  type: string = 'desc') => {
  if (!data) return [];
  let result = data

  return result.sort((a, b) => (Number(a[byKey]) > Number(b[byKey]) ? -1 : 1))
}

export const mergeDedupe = (arr: any) => {
  return Array.from(new Set([].concat(...arr)));
}

export const constructEndpointFromObject = (obj: any) => {
  let endPoint = ``;
  for(let [key, value] of Object.entries(obj)) {
    if (value) endPoint += endPoint ? `&${key}=${value}` : `${key}=${value}`;
  }

  return endPoint;
}

export const getCurrencyUrl = (
  currency: string,
  addressOrTx: string,
  type: 'address' | 'tx'): URL =>{

  let url
  switch (currency) {
    case 'BNB':
    case 'BUSD':
      url = 'https://bscscan.com'
      break;
    case 'BTC':
      url = 'https://blockstream.info'
      break;
    case 'ETH':
      url = 'https://etherscan.io'
      break;
  }

  if(url)
  url = url + '/' + type + '/' + addressOrTx

  return url
}