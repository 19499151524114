import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8c0e47b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center flex-shrink-0" }
const _hoisted_2 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_3 = {
  class: "cursor-pointer d-flex align-items-center",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_4 = { class: "user-image me-3" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "user-info symbol symbol-30px symbol-md-40px d-sm-block d-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.authUser.icon_path,
            alt: "metronic"
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.authUser.first_name) + " " + _toDisplayString(_ctx.authUser.last_name), 1)
      ]),
      _createVNode(_component_KTUserMenu)
    ])
  ]))
}