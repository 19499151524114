import ApiService from "@/core/services/ApiService";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import { API_USERS} from "@/router/ApiRoutes";

@Module
export default class UsersModule extends VuexModule {
  @Action
  fetchUsers({ filters, pagination }) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      const config = {
        params: {
          ...filters,
          ...pagination
        }
      }
      ApiService.get(API_USERS.GET_ALL_USERS, config)
        .then(res => {
          resolve(res.data);
        }).catch(err => {
          console.log('err => ', err);
          reject(err);
        })
    })
  }

  @Action
  fetchStatistics(params: any ) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(API_USERS.USER_STATISTICS, {params})
          .then(res => {
            resolve(res.data);
          }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action
  fetchProductStatistics(params: any ) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(API_USERS.PRODUCT_STATISTICS, {params})
          .then(res => {
            resolve(res.data);
          }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action
  fetchFileCoinStatistics(params: any ) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(API_USERS.FILECOIN_STATISTICS, {params})
          .then(res => {
            resolve(res.data);
          }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action
  fetchFirstMonthCardUsers(params: any ) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(API_USERS.MONTH_CARD_SUERS, {params})
          .then(res => {
            resolve(res.data);
          }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action
  fetchFileCoinUserStatistics(params: any ) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(API_USERS.FILECOIN_STATISTICS_USER, {params})
          .then(res => {
            resolve(res.data);
          }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action
  fetchBtcStatistics(params: any ) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(API_USERS.BTC_STATISTICS, {params})
          .then(res => {
            resolve(res.data);
          }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action
  fetchBtcUserStatistics(params: any ) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(API_USERS.BTC_STATISTICS_DATA, {params})
          .then(res => {
            resolve(res.data);
          }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action
  fetchMoonbergStatistics(params: any ) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(API_USERS.MOONBERG_STATISTICS, {params})
          .then(res => {
            resolve(res.data);
          }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action
  fetchMoonbergUserStatistics(params: any ) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(API_USERS.MOONBERG_STATISTICS_DATA, {params})
          .then(res => {
            resolve(res.data);
          }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }




  @Action
  fetchPapStatistics(params: any ) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(API_USERS.USER_PAP_STATISTICS, {params})
          .then(res => {
            resolve(res.data);
          }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action
  fetchCashPromoStatistics(params: { identifier: string} ) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(API_USERS.USER_CASH_PROMO_STATISTICS, {params})
          .then(res => {
            resolve(res.data?.result);
          }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action({rawError: true})
  updateUser(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_USERS.USER_UPDATE, payload)
        .then(res => {
          resolve(res.status === 200);
        }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action({rawError: true})
  updateNotification(payload:any) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_USERS.UPDATE_NOTIFICATION, payload)
          .then(res => {
            resolve(res.status === 200);
          }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action({rawError: true})
  fetchUserDetails(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      const config = {
        params: {
          ...payload
        }
      }
      ApiService.get(API_USERS.USER_DETAILS, config)
        .then(res => {
          console.log(res)
          resolve(res.data);
        }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action({rawError: true})
  getImpersonationToken({id = null, email = null, walletAddress = null}) {
    ApiService.setHeader();
    const param = id ? 'id' : email ? 'email' : walletAddress ? 'wallet_address' : null;
    const value = id ? id : email ? email : walletAddress ? walletAddress : null;
    if(!param || !value) {
      throw new Error('Invalid parameters id, email or walletAddress is required for impersonation');
    }
    return new Promise((resolve, reject) => {
      ApiService.get(API_USERS.USER_IMPERSONATE, {params: {[param]: value}})
        .then(res => {
          resolve(res.data);
        }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    });
  }

  @Action({rawError: true})
  gymstreetUserRejectKyc({walletAddress, rejectMessage}) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_USERS.USER_REJECT_KYC, {
        wallet_address: walletAddress,
        reject_message: rejectMessage
      })
        .then(res => {
          resolve(res.data);
        }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    });
  }
}
