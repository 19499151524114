
import { computed, defineComponent } from "vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTUserMenu
  },

  setup(){
    const store = useStore();
    const authUser = computed(() => {
      return store.getters.currentUser;
    });

    return{
      authUser
    }
  },
});
