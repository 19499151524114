<template>
  <div>
    <el-form :model="usersFilterForm" class="demo-form-inline" label-width="120px" label-position="right" style="max-width: 600px">
      <el-form-item :label="$t('Id')">
        <el-input clearable v-model.trim="usersFilterForm.id" :placeholder="$t('Search by id')" />
      </el-form-item>
      <el-form-item :label="$t('Email')">
        <el-input clearable v-model.trim="usersFilterForm.email" :placeholder="$t('Search by email')" />
      </el-form-item>
      <el-form-item :label="$t('Username')">
        <el-input clearable v-model.trim="usersFilterForm.username" :placeholder="$t('Search by username')" />
      </el-form-item>
      <el-form-item :label="$t('Wallet address')">
        <el-input clearable v-model.trim="usersFilterForm.wallet" :placeholder="$t('Search by wallet address')" />
      </el-form-item>
      <el-form-item :label="$t('KYC cardholder ID')">
        <el-input clearable v-model.trim="usersFilterForm.cardholder_id" :placeholder="$t('Search by KYC CardHolder ID')" />
      </el-form-item>
      <el-form-item>
        <el-button class="mr-4" type="primary" @click="handleFilterUsers">{{ $t('Apply') }}</el-button>
        <el-button type="secondary" plain  @click="handleClearFilter">{{ $t('Clear') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "UsersFilter",
  emits: ['onFilterUsers'],
  props: {
    usersFilterForm: Object
  },
  methods: {
    handleFilterUsers() {
      const filteredFields = Object.keys(this.usersFilterForm).filter(itemKey => this.usersFilterForm[itemKey]).reduce((obj, key) => {
        obj[key] = this.usersFilterForm[key];
        return obj;
      }, {});
      this.$router.push({ query: { ...filteredFields } })
      this.$emit('onFilterUsers');
    },
    handleClearFilter() {
      Object.keys(this.usersFilterForm).map(item => {
        this.usersFilterForm[item] = ''
      })
      this.$router.push({ query: {} })
      this.$emit('onFilterUsers');
    }
  }
};
</script>

<style scoped>

</style>
