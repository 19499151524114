<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-6">
        <el-date-picker
            v-model="defaultValue"
            type="daterange"
            unlink-panels
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            value-format="YYYY-MM-DD"
            :shortcuts="shortcuts"
            :size="size"
            :disabledDate="disabledDate"
        />
      </div>
      <div class="col-lg-6">
        <p>Most purchased  product : <b><span class="text-success" v-if="mostPurchasedProduct">{{PRODUCTS[Number(mostPurchasedProduct.productId)]}}
          ({{mostPurchasedProduct.totalQuantity.toFixed(2)}} $)</span></b></p>
        <p>Less purchased  product : <b><span class="text-danger" v-if="lessPurchasedProduct">{{PRODUCTS[Number(lessPurchasedProduct.productId)]}}
          ({{lessPurchasedProduct.totalQuantity.toFixed(2)}} $)</span></b></p>
      </div>
    </div>
    <div><hr></div>
    <div class="row">
      <div class="col-lg-12">
        <Chart v-if="products" :products="products"/>
      </div>
      <div class="col-lg-12">
        <Pie v-if="products" :products="products"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {ref, watch} from "vue";
  import {useStore} from "vuex";
  import Chart from "@/pages/statistics/Products/Chart.vue";
  import Pie from "@/pages/statistics/Products/Pie.vue";
  import {PRODUCTS} from "../../../core/config/constants";

  const store = useStore();
  const size = ref<'default' | 'large' | 'small'>('default')
  const dateNow = new Date()
  const products = ref()
  const mostPurchasedProduct = ref()
  const lessPurchasedProduct = ref()
  const dateByDays = (day,currentDate) => currentDate.setTime(currentDate.getTime() - 3600 * 1000 * 24 * day)
  const dateToISO = (date) => new Date(date).toISOString().split('T')[0]
  const defaultValue = ref([dateToISO(dateByDays(7,new Date())),dateToISO(new Date())])

  watch(defaultValue,async () => {
    const [from,to] = defaultValue.value;
    products.value =  await store.dispatch('fetchProductStatistics', {from,to});
    mostPurchasedProduct.value = products.value.reduce((prev, current) => (prev.totalQuantity > current.totalQuantity) ? prev : current)
    lessPurchasedProduct.value = products.value.reduce((prev, current) => (prev.totalQuantity < current.totalQuantity) ? prev : current)
  },{immediate: true})

  const shortcuts = [
    {text: 'Today', value: () => [dateNow, dateNow]},
    {text: 'Last week', value: () =>  [dateByDays(7,new Date()),dateNow]},
    {text: 'Last month', value: () => [dateByDays(30,new Date()),dateNow]},
  ]
  const disabledDate = (time) =>{
    return time.getTime() > Date.now() || time.getTime() < new Date('2023-09-14').getTime();
  }


</script>

<style>
  .el-date-editor{
    width: 100% !important;
  }
</style>
