<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-6">
        <p style="font-size: 18px"><b><span class="text-success" v-if="totalRemainingValue"> Total remaining value :
          ({{(totalRemainingValue.totalBalance  + 2500).toFixed(2)}} $)</span></b></p>
      </div>
      <div class="col-lg-6">
        <p style="font-size: 18px"><b><span class="text-success" v-if="usersActive"> Total active users :
          ({{usersActive.totalCount}})</span></b></p>
      </div>
    </div>
    <hr>
    <div class="row mt-5">
      <div class="col-lg-12" >
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="Active Users" name="active">
              <UsersTable  v-if="usersActive" :users="usersActive.data"/>
          <el-pagination background layout="prev, pager, next"
                         :total="Number(usersActive.totalCount)"
                         :page-size="perPage"
                         v-model:current-page = "pageActive"
                         @current-change="changeActive"
          />
          </el-tab-pane>
          <el-tab-pane label="Inactive Users" name="inactive">
              <UsersTable  :users="usersInactive.data"/>
            <div class="mt-5 text-center ">
              <el-pagination v-if="usersInactive.totalCount" background layout="prev, pager, next"
                             :total="usersInactive.totalCount"
                             :page-size="perPage"
                             v-model:current-page = "pageInactive"
                             @current-change="changeInactive"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
  import {useStore} from "vuex";
import UsersTable from "@/pages/statistics/Filecoin/UsersTable.vue";
  const store = useStore();
  const totalRemainingValue= ref(0);
  const pageActive = ref(1);
  const pageInactive = ref(1);
  const perPage = ref(50);
  const usersActive = ref({
    totalCount: 0,
    data: []
  });
  const usersInactive = ref({
    totalCount: 0,
    data: []
  });
  const activeName = ref('active')

  const  changePage =  async (page,type = 'active')=>{
    const typeName = type === 'active' ? 'active' : 'inactive';
    const result = await store.dispatch('fetchFileCoinUserStatistics', {type:typeName,page: page.value,perPage: perPage.value});
    if(type === 'active') {
      usersActive.value.data = result.data;
      usersActive.value.totalCount = result.totalCount;
    } else {
      usersInactive.value.data = result.data;
      usersInactive.value.totalCount = result.totalCount;
    }
  }

  const changeActive = async (page) => {
      pageActive.value = page;
      await changePage(pageActive)
    }

   const changeInactive = async (page) => {
      pageInactive.value = page;
      await changePage(pageInactive,'inactive')
    }

const handleClick =  async (tab, event) => {
    if(tab.props.name === 'active') {
       await changePage(pageActive)
    } else {
        await changePage(pageInactive,'inactive')
    }
  }

onMounted(async () => {
    totalRemainingValue.value = await store.dispatch('fetchFileCoinStatistics');
  console.log(totalRemainingValue.value);
  await changePage(pageActive)
  })
</script>

<style scoped lang="scss">

</style>
