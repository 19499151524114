<template>
  <el-dialog
    v-model="isVisible"
    width="860px"
    custom-class="custom-dialog"
    @close="handleOnClose"
    :align-center="true"
  >
    <div class="text-center">
      <div>
        <el-table
            v-if="paymentData"
            :data="Object.keys(paymentData)"
            class="mt-5 mb-5"
            :show-header="false"
        >
          <el-table-column prop="key" width="200" >
            <template #default="scope">
              <div  class="text-capitalize fw-bold">
                <template v-if="scope.row === 'user_wallet_address'">
                  Purchased for
                </template>
                <template v-else>
                  {{ scope.row.replace(/_/g, ' ') || 'Himself' }}
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="value" width="600">
            <template #default="scope">
              <div class="d-flex align-items-center">
                <div
                    v-if="scope.row === 'status'"
                    class="w-100 d-flex justify-content-between align-items-center gap-5"
                >
                  <StatusInfo
                      class="w-25"
                      :text="paymentData[scope.row].statusText"
                      :className="paymentData[scope.row].statusClassName"
                  />
                  <el-button
                      v-if="paymentData[scope.row].statusName === 'expired'"
                      type="warning"
                      round
                      @click="handlePaymentReprocess(paymentData.id, paymentData.payment_uid)"
                      v-loading="paymentReprocessLoad"
                  >
                    Reprocess
                  </el-button>
                  <el-button
                      v-if="isSuperadmin && paymentData[scope.row].statusName === 'created'"
                      type="danger"
                      round
                      @click="handlePaymentProcessAnyway(paymentData.id, paymentData.payment_uid)"
                      v-loading="paymentProcessAnywayLoad"
                  >
                    Process payment anyway
                  </el-button>
                </div>
                <div v-else class="fst-italic">
                  <template v-if="[
                      'payment_uid',
                      'grant_transaction_hash',
                      'payment_wallet_address',
                      'user_wallet',
                      'user_email',
                      'distrib_tx_hash'
                      ].includes(scope.row)">
                    <ClipboardCopier
                        v-if="paymentData[scope.row]"
                        :value="paymentData[scope.row]"
                    >
                      <template v-if="[
                      'grant_transaction_hash',
                      'payment_wallet_address',
                      'user_wallet',
                      'distrib_tx_hash'
                      ].includes(scope.row)">
                        <CurrencyUrl
                            :currency="paymentData.currency"
                            :address-or-tx="paymentData[scope.row]"
                            :type="[
                                'grant_transaction_hash',
                                'distrib_tx_hash'
                              ].includes(scope.row) ? 'tx' : 'address'"  />
                      </template>
                      <template v-else>
                        {{ paymentData[scope.row] }}
                      </template>
                    </ClipboardCopier>
                  </template>
                  <template v-else>
                    {{ paymentData[scope.row] }}
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <template #footer>
        <span class="dialog-footer">
          <el-button
              class="btn btn-outline-secondary text-gray-600"
              @click="closePopup"
          >
            {{ $t('Close') }}
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>
<script>
import StatusInfo from "../../../shared-components/utils/StatusInfo";
import ClipboardCopier from "../../../shared-components/ClipboardCopier";
import store from "../../../store";
import NotificationService from "../../../core/services/NotificationService";
import { computed } from "vue";
import CurrencyUrl from "../../../shared-components/CurrencyUrl";
import { UserRoleEnum } from "../../../store/enums/UserRoleEnum";

export default {
  name: "PaymentViewModal",
  components: { CurrencyUrl, ClipboardCopier, StatusInfo },
  props: ["popupVisible", "paymentData", "paymentsContainer"],
  emits: ["handle-on-close", "handle-payment-reprocess"],

  setup(){
    const isSuperadmin = computed(() => {
      return store.getters.currentUser.role === UserRoleEnum.SUPERADMIN;
    });

    return { isSuperadmin }
  },
  data() {
    return {
      isVisible: false,
      paymentReprocessLoad: false,
      paymentProcessAnywayLoad: false
    };
  },
  watch: {
    popupVisible(newVal){
      this.isVisible = newVal
    },
  },
  methods: {
    closePopup() {
      this.isVisible = false
    },
    handleOnClose() {
      this.$emit("handle-on-close");
    },
    async handlePaymentReprocess(id, paymentUID){
      this.paymentReprocessLoad = true
      try {
        let res = await store.dispatch("paymentReprocessAction", { payment_uid: paymentUID });
        if (res) {
          NotificationService.swalNotify({ title: this.$t('Payment status has changed')})
          this.$emit("handle-payment-reprocess",id);
        }
      } catch (err) {
        NotificationService.swalError(this.$t('Payment reprocess error'))
        console.error(err)
      } finally {
        this.paymentReprocessLoad = false
      }
    },
    async handlePaymentProcessAnyway(id, paymentUID){
      this.paymentProcessAnywayLoad = true
      try {
        let res = await store.dispatch("paymentProcessAnywayAction", { payment_uid: paymentUID });
        if (res) {
          // not implemented yet
          // this.$emit("handle-payment-reprocess",id);
        }
      } catch (err) {
        NotificationService.swalError(this.$t('Payment process anyway error'))
        console.error(err)
      } finally {
        this.paymentProcessAnywayLoad = false
      }
    },
  },
};
</script>

<style lang="scss">
  .custom-dialog {
    margin: 70px auto;
  }
</style>
