import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column flex-column-fluid bgi-no-repeat bgi-size-cover bgi-attachment-fixed auth-bg",
  style: {"backgroundImage":"url('media/background/auth-bg.jpg')","background-position":"center"}
}
const _hoisted_2 = { class: "d-lg-flex align-items-center w-100 h-lg-100" }
const _hoisted_3 = { class: "col-lg-5 col-12" }
const _hoisted_4 = { class: "d-lg-flex flex-column align-items-end col-lg-7 d-none" }
const _hoisted_5 = { class: "auth-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('Welcome to GYMSTREET')), 1)
      ])
    ])
  ]))
}