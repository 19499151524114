
export const API_AUTH = {
  LOGIN: '/auth/login-credentials',
};

export const API_USER = {
  GET_AUTH_USER: 'auth/user'
}

export const API_UPLOADS = {
  GET_ALL_UPLOADS: 'gallery/all',
  APPROVE_IMAGE: 'gallery/approve',
  REJECT_IMAGE: 'gallery/reject'
}

export const API_PAYMENTS = {
  GET_ALL_PAYMENTS: 'payment/all',
  PAYMENT_REPROCESS: 'payment/reprocess',
  PAYMENT_PROCESS_ANYWAY: 'payment/process-anyway'
}


export const API_USERS = {
  GET_ALL_USERS: 'user/all',
  USER_UPDATE: 'user/update',
  USER_DETAILS: 'binary/user/details',
  USER_IMPERSONATE: 'user/impersonate',
  USER_STATISTICS: 'user/statistics',
  USER_CASH_PROMO_STATISTICS: 'user/cash-promo-statistics',
  USER_PAP_STATISTICS: 'binary/pap-statistics',
  USER_REJECT_KYC: 'user/reject-kyc',
  PRODUCT_STATISTICS: 'statistics/product',
  FILECOIN_STATISTICS: 'statistics/filecoin',
  FILECOIN_STATISTICS_USER: 'statistics/filecoin-users',
  BTC_STATISTICS: 'statistics/btc',
  BTC_STATISTICS_DATA: 'statistics/btc-data',
  MOONBERG_STATISTICS: 'statistics/moonberg',
  MOONBERG_STATISTICS_DATA: 'statistics/moonberg-data',
  MONTH_CARD_SUERS:'cashft/last-users',
  UPDATE_NOTIFICATION: 'cashft/update-notification',
}

export const API_MAINTENANCES
    = {
  GET_ALL_MAINTENANCES: 'maintenance/all',
  CREATE_MAINTENANCE: 'maintenance/create',
  UPDATE_MAINTENANCE: 'maintenance/update',
  DELETE_MAINTENANCE: 'maintenance/delete',
}


const binaryAPI = process.env.VUE_APP_BINARY_SERVICE_URL + 'api/'

export const API_BINARY_SERVICE = {
  SEED_BINARY_BETWEEN: 'binary/seed/binary-between',
  BINARY_USER_ADD_AUTOSHIP: 'binary/user/add-autoship',
  BINARY_USER_UPDATE_RANK: 'binary/user/update-rank',
  GET_ALL_FAST_START: binaryAPI + 'fast-start',
  GET_ALL_CASH_BONUSES: binaryAPI + 'admin/get-all-cash-bonuses',
  GET_RANK_STATISTICS: binaryAPI + 'admin/rank-statsistics',
  GET_DIRECT_SALES_INCENTIVE_QUALIFIED: binaryAPI + 'admin/direct-sales/incentive/users/qualified',
  GET_DIRECT_SALES_INCENTIVE_TOTAL_CASH_REWARDS: binaryAPI + 'admin/direct-sales/incentive/rewards/total',
  THIRD_ANNIVERSARY_INCENTIVE_STATISTICS: binaryAPI + 'admin/third-anniversary-incentive-status',
}

const voteAPI = process.env.VUE_APP_VOTE_SERVICE_URL + 'api/';

export const API_VOTE_SERVICE = {
  GET_CAMPAIGNS: voteAPI + 'campaign',
  GET_CAMPAIGN: voteAPI + 'campaign/edit',
  CREATE_CAMPAIGN: voteAPI + 'campaign/new',
  SAVE_CAMPAIGN: voteAPI + 'campaign/save',
  DELETE_FEATURE: voteAPI + 'campaign/feature'
}
