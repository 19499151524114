import ApiService from "@/core/services/ApiService";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import { API_MAINTENANCES} from "@/router/ApiRoutes";

@Module
export default class MaintenancesModule extends VuexModule {
  @Action
  fetchMaintenances({ filters, pagination }) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      const config = {
        params: {
          ...filters,
          ...pagination
        }
      }
      ApiService.get(API_MAINTENANCES.GET_ALL_MAINTENANCES, config)
        .then(res => {
          resolve(res.data);
        }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action({rawError: true})
  createMaintenance(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_MAINTENANCES.CREATE_MAINTENANCE, payload)
        .then(res => {
          const maintenance = res.data.maintenance
          resolve(maintenance);
        }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action({rawError: true})
  updateMaintenance(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_MAINTENANCES.UPDATE_MAINTENANCE, payload)
        .then(res => {
          resolve(res.status === 200);
        }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action({rawError: true})
  deleteMaintenance(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_MAINTENANCES.DELETE_MAINTENANCE, payload)
        .then(res => {
          resolve(res.status === 200);
        }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }
}
