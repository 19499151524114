<template>
  <el-dialog
    v-model="isVisible"
    width="500px"
    align-center
    @close="handleOnClose"
    :align-center="true"
  >
    <div class="text-center">
      <el-form :model="notificationForm" label-width="60px" class="demo-form-inline" label-position="top">
<!--          el select -->
        <el-form-item :label="$t('Notification type')" >
          <el-select v-model="notificationForm.notificationType" placeholder="Select notification type" class="d-block">
            <el-option
                v-for="item in notificationTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
        <span class="dialog-footer">
          <el-button
              class="btn btn-outline-secondary text-gray-600"
              @click="closePopup"
          >
            {{ $t('Close') }}
          </el-button>
          <el-button
              class="btn btn-primary text-white"
              v-loading="userUpdateLoad"
              @click="handleUpdateUser"
          >
            {{ $t('Send') }}
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>
<script>
import store from "../../../store";
import NotificationService from "../../../core/services/NotificationService";
import {ERROR_STATUSES, NOTIFICATION_TYPES} from "../../../core/config/constants";

export default {
  name: "UserAddNotification",
  props: {
    popupVisible: Boolean,
    userId: Number,
    type:String|null
  },
  emits: ["handle-on-close","handle-get-users"],
  data() {
    return {
      isVisible: false,
      userUpdateLoad: false,
      notificationForm: {
        notificationType:'insufficient_balance'
      },
      notificationTypes: Object.entries(NOTIFICATION_TYPES).map(([key, value]) => ({ label: value, value: key }))
    };
  },
  computed: {
    notificationType(){
      return this.type
    }
  },
  watch: {
    async popupVisible(newVal){
      this.isVisible = newVal
      if(this.isVisible) {
        this.notificationForm.notificationType = this.type ? this.type : null
      }
    },
  },
  methods: {
    closePopup() {
      this.isVisible = false
    },
    handleOnClose() {
      this.$emit("handle-on-close");
    },
    async handleUpdateUser(){
      this.userUpdateLoad = true
      console.log(this.type);
      try {
        let res = await store.dispatch("updateNotification", {
          id: this.userId,
          notificationType: this.notificationForm.notificationType
        });
        if (res) {
          NotificationService.swalNotify({ title: this.$t('Notification has been sent successfully')})
          this.$emit("handle-get-users");
          this.$emit("handle-on-close");
        }
      } catch (err) {
        NotificationService.swalError(this.$t('Something went wrong'))
      } finally {
        this.userUpdateLoad = false
      }
    },
  },
};
</script>

<style lang="scss">
  .custom-dialog {
    margin: 70px auto;
  }
</style>
