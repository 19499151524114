export const ERROR_STATUSES = {
  // Invalid verification code entered during the registration process
  INVALID_VERIFICATION_CODE: 10,

  // Email address exists or the format is invalid
  INVALID_EMAIL_ADDRESS: 20,

  // Can't update the user data, probably because the email address was already set and verified
  INVALID_USER_UPDATE: 30,

  // Authorization related error occurred
  AUTHORIZATION_ERROR: 40,

  // System exception occurred.  In this case the error has also a message from the thrown exception
  EXCEPTION: 50,

  // Invalid arguments were supplied to API
  INVALID_ARGS: 60,

  //Maximum file size exceeded
  MAX_FILE_SIZE_EXCEEDED: 61,

  // Unexpected case occurred in application
  INVALID_CASE: 70,

  // Supplied JWT token is invalid
  INVALID_TOKEN: 80,

  // Supplied email address already exists in the system
  EXISTING_EMAIL: 90,

  // Insufficient permissions for performing the action
  PERMISSION_DENIED: 100,

  // Parcels selection data integrity error
  PARCELS_SELECTION_DATA_INTEGRITY_ERROR: 110,

  // Data not found
  DATA_NOT_FOUND: 120,

  // NULL returned from a call to init-wallet as a value of referrer_code
  INVALID_REFERRER_CODE: 130,

  // User doesn't exist in MLM
  INVALID_MLM_USER: 140,

  // User's wallet_address not miatching with related_wallet_address
  MISMATCHED_WALLET_ADDRESS: 150,

  // Supplied wallet address already exists in the system
  EXISTING_WALLET: 160,

  INVALID_WALLET_SIGNATURE: 170,

  // wallet address not found in the system
  WALLET_NOT_FOUND: 180,

  // User nft balance is not enough to claim
  NOT_ENOUGH_BALANCE: 190,

  // Parcels data is not valid (reserved or owned by someone else)
  WRONG_PARCELS_DATA: 200,

  // EVM returned an error
  EVM_ERROR: 210,

  // User closed the OAuth window for service
  USER_CLOSED_OAUTH_WINDOW: 4001, // TODO remove this if not needed

  // User denied the OAuth request for service
  USER_DENIED_OAUTH_ACCESS: 4002,
};

export const Constants = {
  USER_TMP_LOGO: '/media/avatars/profile-default-icon.svg',
}

export const ERROR_MESSAGES = {
  ITEM_NOT_FOUND: 'Item not found',
  SOMETHING_WENT_WRONG: 'Something went wrong'
}

export const THROW_ERROR = (message, ...args): void => {
  console.error({ message }, args);
}

export const PRODUCTS = {
    0: 'Basic Bundle',
    1: '4 Parcels Bundle',
    2: '10 Parcels Bundle',
    3: '40 Parcels Bundle',
    4: '80 Parcels Bundle',
    5: '140 Parcels Bundle',
    6: '240 Parcels Bundle',
    7: '4 Basic Miner Bundle',
    8: '10 Basic Miner Bundle',
    9: '40 Basic Miner Bundle',
    10: '80 Basic Miner Bundle',
    11: '140 Basic Miner Bundle',
    12: '240 Basic Miner Bundle',
    13: 'Super Bundle R6',
    14: 'Super Bundle R10',
    15: 'Super Bundle R15',
    16: 'Super Bundle R20',
    17: 'Super Bundle R22 S',
    18: 'Super Bundle R22 M',
    19: 'Super Bundle R22 L',
    20: 'Super Bundle R22 XL',
    21: 'Super Bundle R22 XXL',
    25: 'Super Bundle R8',
    26: 'Starter Bundle',
    27: 'Super Bundle R17',
    22: 'Single miner',
    23: 'Single parcel',
    100: 'Explorer Card',
    101: 'Mastery Card',
    505:'Autoship'
}

export const NOTIFICATION_TYPES = {
   card_activation:'Card Activation & Insufficient Balance',
   insufficient_balance:'Insufficient Balance',
   no_notification:'Remove Notification',
   card_deactivation:'Card Deactivation',
}
