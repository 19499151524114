<template>
    <el-dialog
      v-model="isVisible"
      width="700px"
      align-center
      @close="handleOnClose"
      :align-center="true"
    >
      <div class="text-center">
        <el-form :model="rejectKycData" label-width="130px" class="demo-form-inline">
            <el-form-item :label="$t('Reject message')">
                <el-input v-model="rejectKycData.rejectMessage" />
            </el-form-item>
        </el-form>
      </div>
      <template #footer>
          <span class="dialog-footer">
            <el-button
                class="btn btn-outline-secondary text-gray-600"
                @click="closePopup"
            >
              {{ $t('Close') }}
            </el-button>
            <el-button
                class="btn btn-danger text-white"
                v-loading="updateKycStatusLoading"
                @click="handleUpdateUser"
            >
              {{ $t('Reject') }}
            </el-button>
          </span>
      </template>
    </el-dialog>
  </template>
  <script>
  import store from "../../../store";
  import NotificationService from "../../../core/services/NotificationService";
  
  export default {
    name: "UserRejectKyc",
    props: {
      popupVisible: Boolean,
      rejectKycData: Object
    },
    emits: ["handle-on-close", "handle-on-reject-kyc"],
    data() {
      return {
        isVisible: true,
        updateKycStatusLoading: false,
      };
    },
    methods: {
      closePopup() {
        this.isVisible = false
      },
      handleOnClose() {
        this.$emit("handle-on-close");
      },
      async handleUpdateUser(){
        this.updateKycStatusLoading = true
        try {
          const { walletAddress, rejectMessage } = this.rejectKycData
          await store.dispatch("gymstreetUserRejectKyc", {
            walletAddress,
            rejectMessage,
          });
          this.$emit("handle-on-reject-kyc", rejectMessage);
          NotificationService.swalNotify({
            title: this.$t('Rejection message successfully sent'),
            html: this.$t('Now the user is able to re-upload the KYC documents.'),
          })
          this.closePopup()
        } catch (err) {
            NotificationService.swalError(this.$t(err.message))
            console.error(err)
        } finally {
          this.updateKycStatusLoading = false
        }
      },
    },
  };
  </script>
  