<template>
  <div class="chart">
    <div class="row mt-2">
      <div class="col-lg-4">
        <div class="result_block">
          <div class="result_title">{{total.title}}</div>
          <div class="result_value mt-1">
            {{total.total_turnover}}
            <span  :class="{
            'result_percent_down': total.comparison <=0,
            'result_percent_up': total.comparison >0
            }">
            <span v-if="total.comparison < 0" >↓</span>
            <span v-if="total.comparison > 0">↑</span>
            {{total.comparison && !isNaN(total.comparison) ? Math.abs(total.comparison)+"%" : ''}}</span></div>
        </div>
      </div>
      <div v-if="average" class="col-lg-4">
        <div class="result_block">
          <div class="result_title">Average</div>
          <div class="result_value mt-1">
            {{average}}
          </div>
        </div>
      </div>
    </div>
    <apexchart type="area" height="350" :options="chartOptions" :series="series" ></apexchart>
  </div>
</template>


<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    total: {
      type: Object,
      default: {},
    },
    average: {
      type: String,
      default:0 ,
    },
    xTitle: {
      type: String,
      default: '',
    },
    xData: {
      type: Array,
      default: [],
    },
  },
  computed: {

    series() {
      return [{
        name: this.xTitle,
        data:this.xData
      }]
    },
  },

  data() {

    return {
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text:'',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        xaxis: {
        }
      },

    }
  },
}

</script>

<style scoped lang="scss">
    .chart {
      position: relative;
      border: 4px solid #CCCCCC;
      border-radius: 10px;
      margin-top: 25px;
    }
    .result_title{
      font-size: 13px;
      font-weight: 500;
    }
    .result_value{
      font-size: 21px;
      font-weight: bold;
    }
    .result_percent_down{
      color: #F24822;
      font-size: 13px;
      margin-left: 10px;
    }
    .result_percent_up{
      color: #14AE5C;
      font-size: 13px;
      margin-left: 10px;
    }

    .result_block{
      margin-left: 18px;
    }

</style>
