export const english = {
  "Welcome to GYMSTREET": "Welcome to GYMSTREET",
  "email": "Email",
  "password": "Password",
  "Login": "Login",
  "pleaseWait": "Please Wait",
  "Download Mobile App": "Download Mobile App",
  "Privacy Policy": "Privacy Policy",
  "Terms & Conditions": "Terms & Conditions",
  "My Profile": "My Profile",
  "Sign Out": "Sign Out",
  "Dashboard": "Dashboard",
  "User activities": "User activities",
  "Purchases process": "Purchases process",
  "Parcels": "Parcels",
  "Bundles": "Bundles",
  "Miners": "Miners",
  "Electricity": "Electricity",
  "Repair": "Repair",
  "Modifications process": "Modifications process",
  "Voucher(s) applied": "Voucher(s) applied",
  "Miners attached": "Miners attached",
  "Statistical information": "Statistical information",
  "Financial information": "Financial information",
  "User level info": "User level info",
  "System level info": "System level info",
  "Control": "Control",
  "Disable the sales": "Disable the sales",
  "Disable VBTC claiming": "Disable VBTC claiming",
  "Blacklist/Whitelist a user": "Blacklist/Whitelist a user",
  "Approve": "Approve",
  "Reject": "Reject",
  "Reject with message": "Reject with message",
  "GymStreet": "GymStreet",
  "GymNet": "GymNet",
  "Websites": "Websites",
  "Loading": "Loading",
  "User Wallet": "User Wallet",
  "Image": "Image",
  "Uploaded At": "Uploaded At",
  "Updated At": "Updated At",
  "Uploaded By": "Uploaded By",
  "Pending for approval": "Pending for approval",
  "Approved": "Approved",
  "Rejected": "Rejected",
  "Status": "Status",
  "Select status": "Select status",
  "Search by wallet address": "Search by wallet address",
  "Wallet": "Wallet",
  "Apply": "Apply",
  "Pending": "Pending",

}
