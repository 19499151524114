<template>
  <el-dialog
    v-model="isVisible"
    width="860px"
    custom-class="custom-dialog"
    @close="handleOnClose"
    :align-center="true"
  >
    <div class="text-center">
      <div v-if="!isLoading">
        <el-table
            v-if="userData"
            :data="Object.keys(userData)"
            class="mt-5 mb-5"
            :show-header="false"
        >
          <el-table-column prop="key" width="200" >
            <template #default="scope">
              <div class="text-capitalize fw-bold">
                <template v-if="scope.row === 'sponsor_info'">
                  Sponsor wallet
                </template>
                <template v-else>
                  {{ scope.row.replace(/_/g, ' ')}}
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="value" width="600">
            <template #default="scope">
              <div class="d-flex align-items-center">
                <div v-if="[
                    'registration_date',
                    'first_purchase_date',
                    'active_subscription_until'
                    ].includes(scope.row)" class="fst-italic">
                  {{ userData[scope.row] ? simpleDateFormat(userData[scope.row]) : 'N/A' }}
                </div>
                <div v-else-if="scope.row === 'commission_rank'" class="w-100 d-flex justify-content-between align-items-center gap-5">
                  <div> {{ userData[scope.row] }} </div>
                  <el-button
                      v-if="isSuperadmin"
                      class="btn btn-primary"
                      @click="handleUpdateRankModalOpen(userData['wallet_address'], userData['commission_rank'])"
                  >
                    {{ $t('Update commission rank') }}
                  </el-button>
                </div>
                <div v-else-if="scope.row === 'kyc_data' && userData[scope.row]" class="w-100 d-flex justify-content-between align-items-center gap-5">
                  <div> {{ userData[scope.row].status }}
                    <i v-if="userData[scope.row].status == 'rejected'"
                      class="fas fa-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="tooltip"
                      :title="userData[scope.row].error_message"
                    ></i>
                  </div>
                  <el-button
                      v-if="(isSuperadmin || isManager) && userData[scope.row].status == 'pending'"
                      class="btn btn-danger"
                      @click="handleRejectKycModalOpen(userData['wallet_address'])"
                  >
                    {{ $t('Reject KYC') }}
                  </el-button>
                </div>
                <div v-else class="fst-italic">
                  {{ userData[scope.row] || '_' }}
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <CustomLoader />
      </div>
    </div>
    <template #footer>
        <span class="dialog-footer">
          <el-button
              class="btn btn-outline-secondary text-gray-600"
              @click="closePopup"
          >
            {{ $t('Close') }}
          </el-button>
        </span>
    </template>
    <UserUpdateRank
        v-if="updateRankModalData && isSuperadmin"
        :popupVisible="!!updateRankModalData"
        :userUpdateRank="updateRankModalData"
        @handle-on-close="handleUpdateRankModalClose"
        @handle-on-rank-update="handleUpdateRank"
    />
    <UserRejectKyc
        v-if="rejectKycModalData && isManager"
        :popupVisible="!!rejectKycModalData"
        :rejectKycData="rejectKycModalData"
        @handle-on-close="handleRejectKycModalClose"
        @handle-on-reject-kyc="handleRejectKyc"
    />
  </el-dialog>
</template>
<script>
import store from "../../../store";
import { SweetAlertService } from "@/core/services/SweetAlertService";
import { ERROR_MESSAGES } from "../../../core/config/constants";
import CustomLoader from "../../../shared-components/utils/CustomLoader";
import { simpleDateFormat } from "../../../core/helpers/DateHelper";
import UserUpdateRank from "./UserUpdateRank";
import UserRejectKyc from "./UserRejectKyc";
import { UserRoleEnum } from "../../../store/enums/UserRoleEnum";

export default {
  name: "UserViewModal",
  components: { UserUpdateRank, UserRejectKyc, CustomLoader },
  props: ["popupVisible", "userId"],
  emits: ["handle-on-close", "handle-payment-reprocess"],

  data() {
    return {
      userData: null,
      isVisible: false,
      updateRankModalData: null,
      rejectKycModalData: null,
      isLoading: false,
      simpleDateFormat,
    };
  },
  setup() {
    const isSuperadmin = store.getters.currentUser.role === UserRoleEnum.SUPERADMIN;
    const isAdmin = store.getters.currentUser.role === UserRoleEnum.ADMIN || isSuperadmin;
    const isManager = store.getters.currentUser.role === UserRoleEnum.MANAGER || isAdmin;
    return { isSuperadmin, isAdmin, isManager }
  },
  watch: {
    async popupVisible(newVal){
      this.isVisible = newVal
      if(this.isVisible) {
        await this.getUserDetails()
      }
    },
  },
  methods: {
    async getUserDetails() {
      this.isLoading = true
      try {
        const { userDetails } = await store.dispatch("fetchUserDetails", { id: this.userId });
        this.userData = {...userDetails }
      } catch (e) {
        console.error(e);
        SweetAlertService.notify(SweetAlertService.notificationTypes.error, ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }finally {
        this.isLoading = false
      }
    },
    closePopup() {
      this.isVisible = false
    },
    handleOnClose() {
      this.$emit("handle-on-close");
    },
    handleUpdateRankModalOpen(walletAddress, rank) {
      this.updateRankModalData = {
        walletAddress,
        rank
      }
    },
    handleUpdateRankModalClose() {
      this.updateRankModalData = null
    },
    handleUpdateRank(rank) {
      if ( this.userData['commission_rank'] ){
        console.log(rank)
        this.userData['commission_rank'] = rank
      }
    },
    handleRejectKycModalOpen(walletAddress) {
      this.rejectKycModalData = {
        walletAddress,
        rejectMessage: ''
      }
    },
    handleRejectKycModalClose() {
      this.rejectKycModalData = null
    },
    handleRejectKyc(rejectMessage) {
      if ( this.userData['kyc_data'] ){
        this.userData['kyc_data'].status = 'rejected';
        this.userData['kyc_data'].error_message = rejectMessage;
      }
    },
  },
};
</script>

<style lang="scss">
  .custom-dialog {
    margin: 70px auto;
  }
</style>
