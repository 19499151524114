<template>
  <div class="page-content">
    <p style="font-size: 18px;">Users who have purchased cards within the past 60 days.</p>
    <div><hr></div>
    <div v-if="users.users">
      <el-table :data="users.users" style="width: 100%" class="mt-5 mb-5">
        <el-table-column prop="id" :label="$t('User ID')" width="100" show-overflow-tooltip />
        <el-table-column prop="cardholder_id" :label="$t('Holder ID')" width="100" />
        <el-table-column prop="email" :label="$t('Email')" width="250" />
        <el-table-column prop="username" :label="$t('Username')" width="100" />
        <el-table-column prop="wallet_address" :label="$t('Wallet address')" width="400" />
        <el-table-column prop="date" :label="$t('Created date')" width="150" >
          <template #default="scope">
            {{  moment(new Date(scope.row.date)).format('DD/MM/YYYY')}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <CustomLoader />
    </div>
  </div>
</template>

<script setup>
import CustomLoader from "@/shared-components/utils/CustomLoader.vue";
import {onMounted, ref} from "vue";
import store from "@/store";
import moment from "moment/moment";
const users = ref([])
onMounted(async () => {
  users.value = await store.dispatch('fetchFirstMonthCardUsers');
  console.log(users.value);
})

</script>

<style scoped>

</style>
