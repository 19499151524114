<template>
  <div class="page-content">
    <el-form :model="usersStatisticsForm" :rules="rules"  ref="perUserForm"  class="demo-form-inline" label-width="120px" label-position="right">
      <div class="row">
        <div class="col-lg-4">
          <el-form-item  prop="identifier">
            <el-input clearable v-model.trim="usersStatisticsForm.identifier" prop="identifier" :placeholder="$t('Search by Username or Wallet Address')" />
          </el-form-item>
        </div>
        <div class="col-lg-4">
            <el-date-picker
                style="width: 100%;height: 40px"
                v-model="usersStatisticsForm.range"
                type="daterange"
                align="right"
                size="small"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                value-format="YYYY-MM-DD"
                :default-value="new Date().toISOString().split('T')[0]"
                :disabled-date="disabledDate">
            </el-date-picker>
        </div>
        <div class="col-lg-2">
            <el-select  v-model="usersStatisticsForm.type" @change="typeChange" :placeholder="$t('Select filter type')" style="width: 100%">
              <el-option :label="$t('By registration')" :value="'byCreatedDate'"  />
              <el-option :label="$t('By purchase')" :value="'byPurchaseDate'" />
            </el-select>
        </div>
        <div class="col-lg-2">
            <el-button class="mr-4" type="primary" @click="usersStatisticsFormSubmit">{{ $t('Apply') }}</el-button>
        </div>
      </div>
    </el-form>
    <div><hr></div>
    <div class="row" v-if="usersStatisticsForm.type === 'byCreatedDate'">
      <div class="col-lg-6">
        <Charts  xTitle="Registrations"
                 :total="{
                    title: 'New Registrations',
                    total_turnover: statistics.total.registered_count,
                    comparison:statistics.total.comparison
                  }"
                 :xData="statistics.chart.map(item => {
                  return {
                      x: new Date(item.date).toISOString().split('T')[0],
                      y: item.count
                  }
              })"/>
      </div>
      <div class="col-lg-6">
        <Charts  xTitle="Registrations"
                 :total="{
                    title: 'Active users',
                    total_turnover: statistics.total.active_children,
                  }"
                 :xData="statistics.chart.map(item => {
                  return {
                      x: new Date(item.date).toISOString().split('T')[0],
                      y: item.active_children
                  }
              })"/>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-12">
        <Charts  xTitle="Purchase amount"
            :total="{
              title: 'Total Purchases',
              total_turnover:statistics.total.total_turnover ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
               .format(statistics.total.total_turnover): 0.00,
              comparison: statistics.total.comparison
            }"
            :average="statistics.total.total_avg ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format( statistics.total.total_avg) : 0.00
           "
            :xData="statistics.chart.map(item => {
            return {
                x: new Date(item.date).toISOString().split('T')[0],
                y: item.count.toFixed(2)
            }
          })"/>
      </div>
    </div>
    <div><hr></div>
    <div v-if="statistics.chart">
      <el-table :data="statistics.chart" style="width: 100%" class="mt-5 mb-5">
        <el-table-column :prop="count" :label="$t('Result')" :formatter="formatterSum"/>
        <el-table-column prop="date" :label="$t('Date')"  show-overflow-tooltip :formatter="formatter" />
      </el-table>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import Charts from "@/pages/gymstreet/users/Charts.vue";

  export default {
    name: "PerUser",
    components: {Charts},
    data() {
      return {

        rules: {
          identifier: [
            { required: true, message: 'Please input identifier', trigger: 'blur' },
          ],
        },
        statistics:{
          total:{},
          chart:[],
        }

        ,
        usersStatisticsForm: {
          identifier: '',
          type: 'byCreatedDate',
          range: [new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0]
            ,new Date().toISOString().split('T')[0]],
        },
      };
    },

    methods: {
     async usersStatisticsFormSubmit() {
       const valid = await this.$refs.perUserForm.validate();
        if (!valid) return;
       const [from,to]=this.usersStatisticsForm.range;
       const data = {
         from,
         to,
         identifier: this.usersStatisticsForm.identifier,
         type: this.usersStatisticsForm.type,
         formType: 'perUser'
       }
       this.statistics = await store.dispatch("fetchStatistics", data);
       console.log(this.statistics);
     },
      disabledDate(time) {
        return time.getTime() > Date.now() || time.getTime() < new Date('2023-09-14').getTime();
      },
      formatter(row, column) {
        return new Date(row.date).toISOString().split('T')[0];
      },

      formatterSum(row, column) {
        return this.usersStatisticsForm.type === 'byCreatedDate' ? row.count : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
          .format(row.count);
      },
      typeChange(){
        this.statistics ={
          total:{},
          chart:[],
        }
      }
    }
  };
</script>

<style scoped lang="scss">
:deep(.el-form-item__content)
{
  margin-left: 0 !important;
}
</style>
