<template>
  <div>
    <el-form  label-width="250px" :model="form" :rules="validationRules" ref="form">
      <el-form-item
          v-for="(field, index) in formFields"
          :key="index"
          :label="$t(field.label)"
          :prop="field.name"
          :class="field.type === 'switch' || field.type === 'datetimerange' ? 'w-min-content' : ''"
      >
        <el-input
          v-if="field.type === 'text'"
          v-model.trim="form[field.name]"
          :placeholder="field.placeholder"
          :disabled="field.disable"
          clearable >
        </el-input>
        <el-checkbox
            v-else-if="field.type === 'check'"
            v-model="form[field.name]"
            type="check"
            :disabled="field.disable"
            :placeholder="field.placeholder">
        </el-checkbox>
        <el-switch
            v-else-if="field.type === 'switch'"
            v-model="form[field.name]"
            :disabled="field.disable">
        </el-switch>
        <el-radio-group  v-else-if="field.type === 'radio-group'"
                         v-model="form[field.name]"
                         :disabled="field.disable">
          <el-radio  v-for="(groupField, index) in field.groupFields"
                     :key="String(index) + groupField.name"
                     :label="groupField.value"
                     border
          >
            {{ groupField.name }}
          </el-radio>
        </el-radio-group>
        <el-date-picker
            v-else-if="field.type === 'datetimerange'"
            v-model="form[field.name]"
            type="datetimerange"
            :start-placeholder="$t(field.startPlaceholder)"
            :end-placeholder="$t(field.endPlaceholder)"
            :disabled="field.disable"
        ></el-date-picker>
        <div v-if="field.desc" class="description text-bg-light text-primary">
          <el-icon>
            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728="">
              <path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296-44.096 0-108.992 44.736-148.48 101.504 0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04 67.84 0 107.904-43.648 147.456-100.416z">
              </path>
            </svg>
          </el-icon>
          {{ field.desc }}
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    formStyle: {
      type: Object,
    },
    formData: {
      type: Object
    },
    formFields: {
      type: Array,
      required: true
    },
    validationRules: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {}
    };
  },
  mounted(){
    if(this.formData){
      for (const field of this.formFields) {
        this.form[field.name] = this.formData[field.name]
      }
    }else{
      this.$refs.form.resetFields()
    }
  },
  methods: {
    getForm() {
      return {
        elForm: this.$refs.form,
        formFields: this.form
      }
    }
  }
};
</script>

<style scoped>
  .w-min-content {
    width: fit-content;
  }

  .description {
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 22px;
  }
</style>
