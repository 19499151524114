<template>
  <div>
    <el-form :model="maintenancesFilterForm" class="demo-form-inline" label-width="150px" label-position="left" style="max-width: 600px">
      <el-form-item :label="$t('Id')">
        <el-input clearable v-model="maintenancesFilterForm.id" :placeholder="$t('Search by id')" />
      </el-form-item>
      <el-form-item :label="$t('Message')">
        <el-input clearable v-model="maintenancesFilterForm.message" :placeholder="$t('Search by message text')" />
      </el-form-item>
      <el-form-item>
        <el-button class="mr-4" type="primary" @click="handleFilterMaintenances">{{ $t('Apply') }}</el-button>
        <el-button type="secondary" plain  @click="handleClearFilter">{{ $t('Clear') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "MaintenancesFilter",
  emits: ['onFilterMaintenances'],
  props: {
    maintenancesFilterForm: Object
  },
  methods: {
    handleFilterMaintenances() {
      const filteredFields = Object.keys(this.maintenancesFilterForm).filter(itemKey => this.maintenancesFilterForm[itemKey]).reduce((obj, key) => {
        obj[key] = this.maintenancesFilterForm[key];
        return obj;
      }, {});
      this.$router.push({ query: { ...filteredFields } })
      this.$emit('onFilterMaintenances');
    },
    handleClearFilter() {
      Object.keys(this.maintenancesFilterForm).map(item => {
        this.maintenancesFilterForm[item] = ''
      })
      this.$router.push({ query: {} })
      this.$emit('onFilterMaintenances');
    }
  }
};
</script>

<style scoped>

</style>
