<template>
  <div></div>
</template>
<script>

export default {
  name: "Define",
  created() {
    // TODO change to store user data
    const userData = 6

    if (!userData) {
      this.$router.push('/sign-in');
    } else {
      // this.$router.push('/admin/dashboard');
      this.$router.push('/admin/dashboard');
    }
  }
}
</script>
