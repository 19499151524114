import {ERROR_MESSAGES, THROW_ERROR} from "@/core/config/constants";

export default class MaintenanceContainer {
  maintenances = {} as Map<number, MaintenanceContainerItem>;

  constructor(data: any[]) {
    data.map(dt => this.maintenances[dt.id] = new MaintenanceContainerItem(dt));
  }

  getMaintenances(): MaintenanceContainerItem[] {
    return Object.values(this.maintenances).reverse();
  }

  createMaintenance(maintenance: any): void {
    if (maintenance) {
        this.maintenances[maintenance.id] = new MaintenanceContainerItem(maintenance)
    } else {
      THROW_ERROR(ERROR_MESSAGES.SOMETHING_WENT_WRONG);
    }
  }

  updateMaintenance(maintenance: any): void {
    if (maintenance) {
      this.maintenances[maintenance.id] = new MaintenanceContainerItem(maintenance)
    } else {
      THROW_ERROR(ERROR_MESSAGES.SOMETHING_WENT_WRONG);
    }
  }

  deleteMaintenance(maintenanceId: number): void {
    if (maintenanceId) {
      delete this.maintenances[maintenanceId]
    } else {
      THROW_ERROR(ERROR_MESSAGES.SOMETHING_WENT_WRONG);
    }
  }

}

class MaintenanceContainerItem {
  id: number;
  message: string;
  maintenance: [ Date, Date ];
  countdown: [ Date, Date ];
  global_alert: boolean;
  is_for_auth_users: boolean;

  constructor(obj: any) {
    this.id = obj.id;
    this.message = obj.message;
    this.maintenance = [ obj.maintenance_start,  obj.maintenance_end ] ;
    this.countdown = [ obj.countdown_start, obj.maintenance_end  ]
    this.global_alert = obj.global_alert;
    this.is_for_auth_users = obj.is_for_auth_users;
  }
}