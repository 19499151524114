import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API_AUTH, API_USER } from "@/router/ApiRoutes";
import { redirectToRouteWithName } from "@/core/helpers/UrlHelper";
import NotificationService from "@/core/services/NotificationService";
import { UserModel } from "@/store/models/UserModel";

@Module
export default class AuthModule extends VuexModule {
  authControlData = {
    email: "",
    password: ""
  };
  errors = {};
  user = {};
  isAuthenticated = !!JwtService.getToken();
  registerSetupData = {};

  get registerSetupInformation() {
    return this.registerSetupData;
  }

  get authControlConfigs() {
    return this.authControlData;
  }

  @Mutation
  setRegisterSetupData(setupData) {
    this.registerSetupData = setupData;
  }

  @Mutation
  setAuthConfigEmail(email) {
    this.authControlData.email = email;
  }

  @Mutation
  setAuthConfigPassword(password) {
    this.authControlData.password = password;
  }

  /**
   * Get current user object
   * @returns User
   */
  get currentUser() {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  STORE_ACCESS_TOKEN(token) {
    JwtService.saveToken(token);
  }

  @Mutation
  STORE_REFRESH_TOKEN(token) {
    JwtService.saveRefreshToken(token);
  }

  @Mutation
  [Mutations.SET_AUTH]() {
    this.isAuthenticated = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_USER](userData) {
    this.user = new UserModel(userData);
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as UserModel;
    this.errors = [];
    JwtService.destroyToken();
    JwtService.destroyRefreshToken();
  }


  @Action
  [Actions.LOGOUT]() {
    ApiService.setHeader();
    ApiService.post("/auth/logout", {})
      .finally(() => {
        this.context.commit(Mutations.PURGE_AUTH);
        redirectToRouteWithName("sign-in");
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (!JwtService.getToken()) {
      this.context.commit(Mutations.PURGE_AUTH);
      redirectToRouteWithName("sign-in");
    }
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post(API_AUTH.LOGIN, credentials)
        .then(response => {
          if (response.status === 200) {
            this.context.commit("STORE_ACCESS_TOKEN", response.data.token);
            this.context.commit(Mutations.SET_AUTH);
            this.context.commit(Mutations.SET_USER, response.data.user);

            window.location.href = '/dashboard'
          }
        })
        .catch(error => {
          NotificationService.swalError(error);
          this.context.commit(Mutations.SET_ERROR, error);
          reject();
        });
    });
  }

  @Action
  fetchAuthUserData() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(API_USER.GET_AUTH_USER)
        .then(res => {
          this.context.commit(Mutations.SET_AUTH);
          this.context.commit(Mutations.SET_USER, res.data.user);
          resolve(true)
        }).catch(err => {
          console.log('err => ', err);
          reject();
        })
    })
  }
}
