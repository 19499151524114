<template>
    <div class="flex-column content-loader" :style="{ height }">
      <div class="d-flex align-items-center mt-5">
        <span class="spinner-border text-primary" role="status"></span>
        <span v-if="textVisible" class="text-muted fs-6 fw-bold ms-5">{{$t('Loading')}}...</span>
      </div>
    </div>
</template>
<script>
  export default {
    name: 'CustomLoader',
    props: {
      height: {
        type: String,
        default: ''
      },
      textVisible: {
        type: Boolean,
        default: true
      }
    }
  }
</script>
<style lang="scss">
  .content-loader{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
