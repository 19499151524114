import { Constants } from "@/core/config/constants";
import { BaseModel } from "@/store/models/base/BaseModel";

export class UserModel extends BaseModel {
  first_name: String;
  last_name: String;
  email: String;
  wallet_address: String;
  role: String;
  icon_path: String;
  last_login_time: Date;

  constructor(obj: any) {
    super(obj);

    this.first_name = obj.first_name;
    this.last_name = obj.last_name;
    this.email = obj.email;
    this.wallet_address = obj.wallet_address;
    this.role = obj.role;
    this.icon_path = obj.icon_path || Constants.USER_TMP_LOGO;
    this.last_login_time = new Date(obj.last_login_time);
  }

  getFullName() {
    return this.first_name + ' ' + this.last_name;
  }

  selfUpdate(data) {
    for (let [key, value] of Object.entries(data)) {
      this[key] = value;
    }
  }
}
