<template>
  <div class="page-content">
    <div>
      <MaintenancesFilter :maintenancesFilterForm="maintenanceFilterForm" @onFilterMaintenances="handleFilterMaintenances" />
    </div>
    <div><hr></div>
    <div>
      <el-button type="primary" @click="handleModalAction(true)">
        {{ $t("+ Add Maintenance") }}
      </el-button>
    </div>
    <div v-if="maintenanceContainer && !maintenanceLoad">
      <el-table :data="maintenanceContainer.getMaintenances()" style="width: 100%" class="mt-5 mb-5">
        <el-table-column prop="id" :label="$t('ID')" width="80" show-overflow-tooltip />
        <el-table-column prop="message" :label="$t('Message')" width="220" show-overflow-tooltip/>
        <el-table-column prop="maintenance" :label="$t('Maintenance')" width="320" >
          <template #default="scope">
            {{
              simpleDateFormat(scope.row.maintenance[0])
              + ' - '
              + simpleDateFormat(scope.row.maintenance[1])
            }}
          </template>
        </el-table-column>
        <el-table-column prop="countdown" :label="$t('Countdown')" width="320" >
          <template #default="scope">
            {{
              simpleDateFormat(scope.row.countdown[0])
              + ' - '
              + simpleDateFormat(scope.row.countdown[1])
            }}
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-button size="small" type="primary" @click="handleModalAction(true, scope.row)"> {{ $t('Update') }}</el-button>
            <el-button size="small" type="danger" @click="handleDelete(scope.row.id)"> {{ $t('Delete') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-if="pagination"
        :pagination="pagination"
        @onSizeChange="handleSizeChange"
        @onCurrentChange="handleCurrentChange"
      />
      <MaintenanceModalForm
          :form-data="maintenanceForm"
          :popup-visible="maintenanceFormShow"
          @handle-on-close="handleModalAction()"
          @handle-maintenance-operation="handleMaintenanceOperation"
      />
    </div>
    <div v-else>
      <CustomLoader />
    </div>
  </div>
</template>

<script>

import store from "@/store";
import CustomLoader from "@/shared-components/utils/CustomLoader";
import { SweetAlertService } from "@/core/services/SweetAlertService";
import { ERROR_MESSAGES } from "@/core/config/constants";
import Pagination from "@/shared-components/utils/Pagination";
import MaintenanceContainer from "@/store/models/MaintenanceContainer";
import { PaginationModel } from "@/store/models/utils/PaginationModel";
import MaintenancesFilter from "./MaintenancesFilter";
import {computed} from "vue";
import MaintenanceModalForm from "./MaintenanceModalForm";
import FormConstructor from "../../../shared-components/FormConstructor";
import NotificationService from "../../../core/services/NotificationService";
import { simpleDateFormat } from "../../../core/helpers/DateHelper";

export default {
  name: "Maintenances",
  components: {
    FormConstructor,
    MaintenanceModalForm,
    MaintenancesFilter,
    Pagination,
    CustomLoader
  },
  data() {
    return {
      maintenanceLoad: false,
      maintenanceFormShow: false,
      maintenanceContainer: null,
      pagination: {
        page: '',
        per_page: '',
      },
      maintenanceFilterForm: {
        id: '',
        message: '',
      },
      maintenanceForm: null,
      pendingActionsIndexes: new Set(),
      simpleDateFormat
    };
  },
  async mounted() {
    this.handleQuery()
    await this.fetchMaintenances()
  },
  methods: {
    async fetchMaintenances() {
      this.maintenanceContainer = null;
      try {
        let maintenanceRes = await store.dispatch("fetchMaintenances", { filters: { ...this.maintenanceFilterForm }, pagination: { ...this.pagination } });
        this.maintenanceContainer = new MaintenanceContainer(maintenanceRes.maintenance);
        this.pagination = new PaginationModel(maintenanceRes.pagination);
      } catch (e) {
        console.error(e);
        SweetAlertService.notify(SweetAlertService.notificationTypes.error, ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }
    },

    async handleDelete(id) {
      this.maintenanceLoad = true
      try {
        let res = await store.dispatch("deleteMaintenance", { id });
        if (res) {
          this.maintenanceContainer.deleteMaintenance(id)
          NotificationService.swalNotify({ title: this.$t('Maintenance has deleted successfully')})
        }
      } catch (err) {
        console.error(err);
        SweetAlertService.notify(SweetAlertService.notificationTypes.error, ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      } finally {
        this.maintenanceLoad = false
      }
    },

    async handleUpdateMaintenance(maintenance) {
      this.maintenanceLoad = true
      try {
        let res = await store.dispatch("updateMaintenance", maintenance);
        if (res) {
          this.maintenanceContainer.updateMaintenance(maintenance)
          NotificationService.swalNotify({ title: this.$t('Maintenance has updated successfully')})
        }
      } catch (err) {
        console.error(err);
        SweetAlertService.notify(SweetAlertService.notificationTypes.error, ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      } finally {
        this.maintenanceLoad = false
      }
    },
    async handleFilterMaintenances() {
      this.pagination.page = 1;
      await this.fetchMaintenances()
    },
    async handleSizeChange(pageSize) {
      this.pagination.per_page = pageSize;
      await this.fetchMaintenances();
    },
    async handleCurrentChange(currentPage) {
      this.pagination.page = currentPage;
      await this.fetchMaintenances();
    },
    handleModalAction(show = false, data = null) {
      this.maintenanceFormShow = show
      this.maintenanceForm = data
    },
    handleMaintenanceOperation(maintenance, type){
      if(type === 'create'){
        this.maintenanceContainer.createMaintenance(maintenance);
      }else{
        this.maintenanceContainer.updateMaintenance(maintenance);
      }
    },
    handleQuery(){
      const query = this.$route.query
      this.pagination.page = query.page || ''
      this.pagination.per_page =query.per_page || ''

      this.maintenanceFilterForm.id = query.id || ''
      this.maintenanceFilterForm.message = query.message || ''
    }
  }
};
</script>

<style scoped>

</style>
