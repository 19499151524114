<template>
    <div class="page-content">
      <div>
        <h1 class="text-center">Next Rank Qualification progress</h1>
      </div>
      <el-form :model="rankStatisticsForm" :rules="rules"  ref="perUserForm"  class="demo-form-inline" label-width="120px" label-position="right" @submit.prevent="getNextRankStatistics">
        <div class="row">
          <div class="col-lg-4 col-sm-10">
            <el-form-item label="Progress" prop="progress">
              <el-input v-model="rankStatisticsForm.progress" placeholder="Progress"></el-input>
            </el-form-item>
          </div>
          <div class="col-lg-2 col-sm-2">
            <el-button class="mr-4" type="primary" @click="getNextRankStatistics">{{ $t('Filter') }}</el-button>
          </div>
          <div class="col-lg-1 col-sm-0">
          </div>
          <div class="col-lg-5 col-sm-12 h2 mt-2">
            Rewards to be distributed with this progress: {{ toBeDistributed }}$
          </div>
        </div>
      </el-form>
      <div v-if="statistics">
        <el-table :data="statistics" style="width: 100%" class="mt-5 mb-5">
          <el-table-column prop="user.username" :label="$t('Username')"  show-overflow-tooltip  />
          <el-table-column prop="user.currentRank" :label="$t('Current Rank')"  show-overflow-tooltip  />
          <el-table-column prop="nextRank" :label="$t('Next Rank')"  show-overflow-tooltip  />
          <el-table-column prop="progress" :label="$t('Progress')" show-overflow-tooltip>
            <template #default="scope">
              {{ Number(scope.row.progress || 0).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column prop="reward" :label="$t('Expecting Reward')" show-overflow-tooltip/>
          <el-table-column prop="rewardsForLostBonus" :label="$t('Lost Bonus reward')" show-overflow-tooltip/>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagination.perPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
          class="pagination mx-auto"
        />
      </div>
    </div>
  </template>
  
  <script>
  import store from "@/store";
  import Charts from "@/pages/gymstreet/users/Charts.vue";
  
  export default {
    name: "PapStatistics",
    data() {
      return {
        statistics:[],
        toBeDistributed: 0,
        pagination: {
            page: 1,
            perPage: 10,
            total: 0,
        },
        rankStatisticsForm: {
          progress: 90,
        },
      };
    },
  
    async mounted() {
        await this.getNextRankStatistics();
    },

    methods: {
      async getNextRankStatistics() {
        const {data, sum, pagination} = await store.dispatch("binaryRankStatistics", {
            ...this.rankStatisticsForm,
            ...this.pagination,
        });
        this.statistics = data;
        this.toBeDistributed = sum;
        this.pagination.total = pagination.total;
        console.log(this.statistics)
      },
      handleSizeChange(val) {
        this.pagination.page = 1;
        this.pagination.perPage = val;
        this.getNextRankStatistics();
      },
      handleCurrentChange(val) {
        this.pagination.page = val;
        this.getNextRankStatistics();
      }
    }
  };
</script>
  
<style scoped lang="scss">
    :deep(.el-form-item__content)
    {
        margin-left: 0 !important;
    }
    .result_title{
        font-size: 16px;
        font-weight: 500;
    }
    .result_value{
        font-size: 21px;
        font-weight: bold;
    }
    .pagination {
      width: fit-content;
    }
</style>
