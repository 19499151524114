import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AuthRoutes from "@/router/routes/AuthRoutes";
import AdminRoutes from "@/router/routes/AdminRoutes";
import GymstreetRoutes from "@/router/routes/GymstreetRoutes";
import BinaryServiceRoutes from "@/router/routes/BinaryServiceRoutes";

const routes: Array<any> = [
  {
    path: "/",
    redirect: '/dashboard',
    component: () => import("@/layout/main/Layout.vue"),
    children: []
  },
  {
    path: '/dashboard',
    name: 'define',
    component: () => import("@/layout/main/Define.vue"),
  },
  {
    path: "/",
    component: () => import("@/layout/main/Auth.vue"),
    children: AuthRoutes
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/shared-components/error-pages/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

routes[0].children?.push({
  path: '/admin',
  redirect: '/admin/dashboard',
  component: () => import("@/layout/main/AdminLayout.vue"),
  children: AdminRoutes
})

routes[0].children?.push({
  path: '/gymstreet',
  redirect: '/gymstreet/uploads',
  component: () => import("@/layout/main/AdminLayout.vue"),
  children: GymstreetRoutes
})

routes[0].children?.push({
  path: '/binary-service',
  redirect: '/binary-service/fast-start',
  component: () => import("@/layout/main/AdminLayout.vue"),
  children: BinaryServiceRoutes
})

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
