import ApiService from "@/core/services/ApiService";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import { API_PAYMENTS } from "@/router/ApiRoutes";

@Module
export default class PaymentsModule extends VuexModule {
  @Action

  fetchPayments({ filters, pagination }) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      const config = {
        params: {
          ...filters,
          ...pagination
        }
      }
      ApiService.get(API_PAYMENTS.GET_ALL_PAYMENTS, config)
        .then(res => {
          resolve(res.data);
        }).catch(err => {
          console.log('err => ', err);
          reject(err);
        })
    })
  }

  @Action
  paymentReprocessAction(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_PAYMENTS.PAYMENT_REPROCESS, payload)
        .then(res => {
            resolve(res.status === 200);
        }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }

  @Action
  paymentProcessAnywayAction(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_PAYMENTS.PAYMENT_PROCESS_ANYWAY, payload)
        .then(res => {
          resolve(res.status === 200);
        }).catch(err => {
        console.log('err => ', err);
        reject(err);
      })
    })
  }
}
