export enum RankEnum {
  MEMBER = 'Member',
  TRAINEE = 'Trainee',
  ROOKIE = 'Rookie',
  SILVER = 'Silver',
  GOLD = 'Gold',
  PLATIN = 'Platin',
  DOUBLE_PLATIN = 'Double Platin',
  DIAMOND = 'Diamond',
  DOUBLE_DIAMOND = 'Double Diamond',
  RED_DIAMOND = 'Red Diamond',
  WHITE_DIAMOND = 'White Diamond',
  CROWN_DIAMOND = 'Crown Diamond',
}
