<template>
  <el-dialog
      v-model="isVisible"
      width="800px"
      align-center
      @close="handleOnClose"
  >
    <div class="text-center">
      <FormConstructor v-if="isVisible" ref="maintenanceForm" :formFields="formFields" :validationRules="rules" :form-data="formData"  />
    </div>
    <template #footer>
        <span class="dialog-footer">
          <el-button
              class="btn btn-outline-secondary text-gray-600"
              @click="handleClosePopup"
          >
            {{ $t('Close') }}
          </el-button>
          <el-button
              class="btn btn-primary text-white"
              v-loading="maintenanceLoad"
              @click="handleSubmit"
          >
            {{ $t(isCreateMode ? 'Create' : 'Update' ) }}
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>
<script>
import FormConstructor from "../../../shared-components/FormConstructor";
import store from "../../../store";
import NotificationService from "../../../core/services/NotificationService";

export default {
  name: "MaintenanceModalForm",
  components: { FormConstructor },
  props: {
    formData: Object,
    popupVisible: Boolean,
    maintenanceForm: Object
  },
  emits: ["handle-on-close", "handle-maintenance-operation"],
  watch: {
    popupVisible(newVal){
      this.isVisible = newVal
      const mainForm = [
        { name: 'message', label: 'Message', type: 'text', placeholder: 'Enter your message' },
        { name: 'maintenance', label: 'Maintenance date range', type: 'datetimerange', startPlaceholder: 'Start', endPlaceholder: 'End' },
        { name: 'countdown', label: 'Countdown date range', type: 'datetimerange', startPlaceholder: 'Start', endPlaceholder: 'End' },
        { name: 'is_for_auth_users', label: 'Is for auth users', type: 'switch' },
        { name: 'global_alert', label: 'Global alert', type: 'switch' },
      ]

      if(newVal){
        if(!this.isCreateMode){
          this.formFields = [
            { name: 'id', label: 'Id', type: 'text', disable: true },
            ...mainForm,
          ]
        }else{
          this.formFields = [
            ...mainForm
          ]
        }
      }else if (this.$refs.maintenanceForm){
        this.$refs.maintenanceForm.getForm().elForm.resetFields()
      }
    },
  },
  data() {
    return {
      isVisible: false,
      maintenanceLoad: false,
      formFields: [],
      rules: {
        message: [
          {   required: true, type: 'string', trigger: 'blur' },
        ],
        maintenance: [
          {  required: true, type: 'array', trigger: 'blur' },
        ],
        countdown: [
          {  required: true, type: 'array', trigger: 'blur' },
        ],
        is_for_auth_users: [
          { type: 'boolean', trigger: 'blur' },
        ],
        global_alert: [
          {  type: 'boolean', trigger: 'blur' },
        ]
      }
    };
  },
  computed: {
    isCreateMode() {
      return !this.formData
    }
  },
  methods: {
    handleClosePopup() {
      this.isVisible = false
    },
    handleOnClose() {
      this.$emit("handle-on-close");
    },
    async handleSubmit(){
      const _maintenanceForm = this.$refs.maintenanceForm.getForm()
      _maintenanceForm.elForm.validate(async valid => {
        if (valid) {
          this.maintenanceLoad = true

          const fields = _maintenanceForm.formFields
          const payload = {
            message: fields.message,
            maintenance_start: fields.maintenance[0],
            maintenance_end: fields.maintenance[1],
            countdown_start: fields.countdown[0],
            countdown_end: fields.countdown[1],
            is_for_auth_users: fields.is_for_auth_users || false,
            global_alert: fields.global_alert || false,
          }

          if(!this.isCreateMode){
            payload.id = fields.id
          }

          try {
            const response= await store.dispatch(this.isCreateMode ? 'createMaintenance' : 'updateMaintenance', payload);
            if (response) {
              const successMessage = this.isCreateMode ? 'Maintenance has created' : 'Maintenance has updated'
              NotificationService.swalNotify({ title: this.$t(successMessage)})
              const operationType = this.isCreateMode ? 'create' : 'update'
              const result = this.isCreateMode ? response : payload
              this.$emit("handle-maintenance-operation", result, operationType);
              if(this.isCreateMode){
                this.$refs.maintenanceForm.getForm().elForm.resetFields()
                this.$emit("handle-on-close")
              }
            }
          } catch (err) {
            const errMessage = this.isCreateMode ? 'Maintenance create process error' : 'Maintenance update process error'
            NotificationService.swalError(this.$t(errMessage))
            console.error(err)
          } finally {
            this.maintenanceLoad = false
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
.custom-dialog {
  margin: 70px auto;
}

</style>
