
export class PaginationModel {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;

  constructor(obj: any = null) {
    this.page = obj && obj.page || 1;
    this.per_page = obj && obj.per_page || 10;
    this.total = obj && obj.total || 0;
    this.total_pages = obj && obj.total_pages || 1;
  }
}
