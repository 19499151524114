import JwtService from "@/core/services/JwtService";

const authRoutes = [
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/pages/authentication/SignIn.vue"),
    beforeEnter: ((to, from, next) => {
      if (!!JwtService.getToken()) {
        next('/admin/dashboard');
      } else {
        next();
      }
    })
  }
];

export default authRoutes;
