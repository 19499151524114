import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { API_UPLOADS } from "@/router/ApiRoutes";

@Module
export default class UploadsModule extends VuexModule {
  @Action
  fetchUploads({ filters, pagination }) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      const config = {
        params: {
          ...filters,
          ...pagination
        }
      }
      ApiService.get(API_UPLOADS.GET_ALL_UPLOADS, config)
        .then(res => {
          resolve(res.data);
        }).catch(err => {
          console.log('err => ', err);
          reject(err);
        })
    })
  }

  @Action
  approveImage(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_UPLOADS.APPROVE_IMAGE, payload)
        .then(res => {
          resolve(res.status === 200);
        }).catch(err => {
          console.log('err => ', err);
          reject();
        })
    })
  }

  @Action
  rejectImage(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_UPLOADS.REJECT_IMAGE, payload)
        .then(res => {
          resolve(res.status === 200);
        }).catch(err => {
          console.log('err => ', err);
          reject();
        })
    })
  }
}
