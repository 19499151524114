import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import StaticDataModule from "@/store/modules/StaticDataModule";
import UploadsModule from "@/store/modules/gymstreet/UploadsModule";
import PaymentsModule from "@/store/modules/gymstreet/PaymentsModule";
import UsersModule from "@/store/modules/gymstreet/UsersModule";
import MaintenancesModule from "@/store/modules/gymstreet/MaintenancesModule";
import BinaryServiceModule from "@/store/modules/gymstreet/BinaryServiceModule";
import VoteServiceModule from './modules/gymstreet/VoteServiceModule';

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    StaticDataModule,
    UploadsModule,
    PaymentsModule,
    UsersModule,
    MaintenancesModule,
    BinaryServiceModule,
    VoteServiceModule,
  },
});

export default store;
