<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-3 align-self-center">
        <p style="font-size: 18px"><b><span class="text-success" v-if="totalRemainingValue"> Users:
          ({{(totalRemainingValue.totalUsers)}})
        </span></b></p>
      </div>
      <div class="col-lg-3 align-self-center">
        <p style="font-size: 18px"><b><span class="text-success" v-if="totalRemainingValue"> Distributed Tokens :
          ({{totalRemainingValue.totalDistribution.toFixed(2)}})
        </span></b></p>
      </div>
      <div class="col-lg-3 align-self-center">
        <p style="font-size: 18px"><b><span class="text-success" v-if="totalRemainingValue"> Claimed Tokens :
          ({{totalRemainingValue.totalClaimedToken.toFixed(2)}})
        </span></b></p>
      </div>
      <div class="col-lg-3 align-self-center">
        <p style="font-size: 18px"><b><span class="text-success" v-if="totalRemainingValue"> Pending Distribution :
          ({{totalRemainingValue.totalRewardToken.toFixed(2)}})
        </span></b></p>
      </div>
    </div>
    <hr>
    <div class="row mt-5">
      <div class="row">
        <div class="col-lg-6">
          <el-form-item label="Wallet Address">
            <el-input  v-model="walletAddress" placeholder="Wallet Address" @input="filter" clearable></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="col-lg-12" >
        <el-table :data="users.data" style="width: 100%">
          <el-table-column prop="wallet_address" label="User"  />
          <el-table-column prop="reward_amount_token" label="Total Tokens" >
            <template #default="scope">
              {{ scope.row.reward_amount_token.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="total_claimed_token" label="Total Claimed Tokens" >
            <template #default="scope">
              {{ scope.row.total_claimed_token.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="weekly_token" label="Weekly Reward" >
            <template #default="scope">
              {{ scope.row.weekly_token.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="Total Purchase" >
            <template #default="scope">
              {{ scope.row.amount.toFixed(2)}} $
            </template>
          </el-table-column>
        </el-table>
        <el-pagination  v-if="users.totalCount > 25" background layout="prev, pager, next"
                        :total="Number(users.totalCount)"
                        :page-size="perPage"
                        v-model:current-page = "pageUsers"
                        @current-change="changeUsers"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
const store = useStore();
const totalRemainingValue= ref({
  totalClaimedToken:0,
  totalRewardToken:0,
  totalUsers:0,
  totalDistribution:0,
});
const walletAddress = ref('')
const pageUsers = ref(1);
const perPage = ref(25);
const users = ref({
  totalCount: 0,
  data: []
});

const  changePage =  async (page)=>{
  const payload = {page: page.value,perPage: perPage.value};
  if(walletAddress) payload.walletAddress = walletAddress.value;
  const result = await store.dispatch('fetchMoonbergUserStatistics', payload);
  users.value.data = result.data;
  users.value.totalCount = result.totalCount;
}
const changeUsers = async (page) => {
  pageUsers.value = page;
  await changePage(pageUsers)
}
const filter = async () => {
  if(walletAddress.value.length === 42 ) {
    await changePage(pageUsers,'users',walletAddress.value)
  }else {
    await changePage(pageUsers)
  }
}
onMounted(async () => {
  totalRemainingValue.value =  await store.dispatch('fetchMoonbergStatistics',{});
  await changePage(pageUsers)
})
</script>
<style >
  .el-range-editor{
    width: 100% !important;
  }
</style>

