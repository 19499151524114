import moment from "moment";

export const dateTimeNow = () => {
  return moment().format();
}

export const getDateDiff = (start: any, end: any) => {
  let endDate = moment(new Date(start));
  let startDate = moment(new Date(end));

  return endDate.diff(startDate, 'days')+1;
}

export const simpleDateFormat = (date) => {
  return moment(new Date(date)).format('DD/MM/YYYY h:mm:ss')
}
