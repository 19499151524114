<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-3">
            <el-date-picker
                style="width: 100%;"
                v-model="usersStatisticsForm.range"
                size='default'
                type="daterange"
                align="right"
                @change="AnalyticsFormSubmit"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                value-format="YYYY-MM-DD"
                :default-value="new Date().toISOString().split('T')[0]"
                :disabled-date="disabledDate">
            </el-date-picker>
      </div>
      <div class="col-lg-3 d-flex">
        <div class="align-self-center period_block">
          Compare:<span class="pr_period"> Previous period</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <Charts  xTitle="Purchase amount"
            :total="{
              title: 'Total Purchases',
              total_turnover:new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format(statistics.purchaseTotal.total_turnover),
              comparison: statistics.purchaseTotal.comparison
            }"
            :average="new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format( statistics.purchaseTotal.total_avg)
           "
            :xData="statistics.purchaseByDay.map(item => {
            return {
                x: new Date(item.date).toISOString().split('T')[0],
                y: item.count.toFixed(2)
            }
          })"/>
      </div>
      <div class="col-lg-6">
        <Charts  xTitle="Registrations"
                 :total="{
                     title: 'New Registrations',
                    total_turnover: statistics.registrationTotal.registered_count,
                    comparison: statistics.registrationTotal.comparison
                  }"
                 :xData="statistics.registrationByDay.map(item => {
                  return {
                      x: new Date(item.date).toISOString().split('T')[0],
                      y: item.count
                  }
              })"/>
      </div>
      <div class="col-lg-6">
        <Charts  xTitle="Registrations"
                 :total="{
                    title: 'Active users',
                    total_turnover: statistics.registrationTotal.active_children,
                  }"
                 :xData="statistics.registrationByDay.map(item => {
                  return {
                      x: new Date(item.date).toISOString().split('T')[0],
                      y: item.active_children
                  }
              })"/>
      </div>
    </div>
  </div>
</template>

<script>

import store from "@/store";
import CustomLoader from "@/shared-components/utils/CustomLoader";
import Pagination from "@/shared-components/utils/Pagination";
import UsersFilter from "./UsersFilter";
import UserViewModal from "./UserViewModal";
import UserEditModal from "./UserEditModal";
import {computed} from "vue";
import {UserRoleEnum} from "../../../store/enums/UserRoleEnum";
import UserAddAutoship from "./UserAddAutoship";
import Charts from "@/pages/gymstreet/users/Charts.vue";

export default {
  name: "Analytics",
  components: {
    Charts,
    UserAddAutoship,
    UserEditModal,
    UserViewModal,
    UsersFilter,
    Pagination,
    CustomLoader
  },
  data() {
    return {
      userEditModalData: null,
      userAddAuthoshipModalData: null,
      userViewModalData: null,
      usersContainer: null,
      lastWeek:new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
      currentDate:new Date().toISOString().split('T')[0],
      statistics:{
        purchaseByDay:[],
        purchaseTotal:{},
        registrationByDay:[],
        registrationTotal:{},
      },
      usersStatisticsForm: {
        identifier: '',
        range: [new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0]
          ,new Date().toISOString().split('T')[0]],
      },
      pendingActionsIndexes: new Set(),
    };
  },
  setup() {
    const hasAccess = computed(() => {
      const accessibleRoles = [
        UserRoleEnum.SUPERADMIN,
        UserRoleEnum.ADMIN,
        UserRoleEnum.MANAGER
      ]
      return accessibleRoles.includes(store.getters.currentUser.role);
    });
    const isSuperadmin = store.getters.currentUser.role === UserRoleEnum.SUPERADMIN;

    return { hasAccess, isSuperadmin }
  },
  async mounted() {
    const data = {
      from:this.lastWeek,
      to:this.currentDate,
      formType:'total',
    }
    this.statistics = await store.dispatch("fetchStatistics", data);
    console.log(this.statistics,45456);
  },
  methods: {
    disabledDate(time) {
      return time.getTime() > Date.now() || time.getTime() < new Date('2023-09-14').getTime();
    },
    async AnalyticsFormSubmit(){
      const [from,to]=this.usersStatisticsForm.range;
      this.statistics = await store.dispatch("fetchStatistics", {from, to,formType:'total'});
      console.log(this.statistics);
    },
  }
};
</script>

<style scoped>
  .page-content{
    padding: 30px;
  }
  .pr_period{
    color: #6D7B8A;
    font-weight: bold;
  }
  .period_block{
    font-size: 15px;
  }


</style>
