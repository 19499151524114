import store from "@/store";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";

class InitialDataResolver {
  public static async init(platformType: PlatformTypeEnum) {
    switch (platformType) {
      case PlatformTypeEnum.ADMIN:
        await InitialDataResolver.fetchAuthUserData();
        break;
    }
  }

  public static async fetchAuthUserData() {
    await store.dispatch('fetchAuthUserData');
  }
}

export default InitialDataResolver;
