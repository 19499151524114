<template>
    <div class="page-content">
      <div>
        <h1 class="text-center">Third Anniversary Incentive Statistics</h1>
      </div>
      <div>
        <h3 class="result_title">Total Amount to be Distributed: <span class="result_value">{{toBeDistributed}}</span></h3>
      </div>
      <div v-if="statistics">
        <el-table v-loading="loading" :data="statistics" style="width: 100%" class="mt-5 mb-5">
          <el-table-column prop="username" :label="$t('Username')"  show-overflow-tooltip  />
          <el-table-column prop="wallet_address" :label="$t('Wallet')"  show-overflow-tooltip width="500" />
          <el-table-column prop="qualified_turnover" :label="$t('Turnover 50%')"  show-overflow-tooltip  />
          <el-table-column prop="reward" :label="$t('Reward')" show-overflow-tooltip />
        </el-table>
      </div>
    </div>
  </template>
  
  <script>
  import store from "@/store";
  
  export default {
    name: "PapStatistics",
    data() {
      return {
        statistics:[],
        toBeDistributed: 0,
        loading: false,
        pagination: {
            page: 1,
            perPage: 10,
            total: 0,
        },
      };
    },
    async mounted() {
        await this.getThirdAnniversaryIncentiveStatistics();
    },
    methods: {
      async getThirdAnniversaryIncentiveStatistics() {
        this.loading = true;
        const {totalAmountToBeDistributed, usersQualifiedTurnovers} = await store.dispatch("thirdAnniversaryIncentiveStatistics");
        this.statistics = usersQualifiedTurnovers;
        this.toBeDistributed = totalAmountToBeDistributed;
        this.loading = false;
      },
    }
  };
</script>
  
<style scoped lang="scss">
    :deep(.el-form-item__content)
    {
        margin-left: 0 !important;
    }
    .result_title{
        font-size: 16px;
        font-weight: 500;
    }
    .result_value{
        font-size: 21px;
        font-weight: bold;
    }
    .pagination {
      width: fit-content;
    }
</style>
