
import { computed, defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";
import { dateTimeNow } from "@/core/helpers/DateHelper";
import { redirectToRouteWithName } from "@/core/helpers/UrlHelper";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    let rememberMe = ref(false);
    const store = useStore();
    const errorMessage = ref("");

    const submitButton = ref<HTMLButtonElement | null>(null);

    const initialValues = computed(() => {
      return store.getters.authControlConfigs;
    })

    if (!initialValues || !initialValues.value.email) {
      redirectToRouteWithName('sign-in');
    }

    onMounted(() => {
      let passwordField = document.getElementsByName('password');
      if (passwordField && passwordField[0]) passwordField[0].focus();
    })

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().required().label("Password")
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      store.commit(Mutations.PURGE_AUTH);

      errorMessage.value = "";

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      let loginData = {
        ...values,
        ...{ local_time: dateTimeNow() }
      };

      await store.dispatch(Actions.LOGIN, loginData)
        .finally(() => {
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      errorMessage,
      rememberMe,
      initialValues
    };
  }
});
