<template>
  <div class="mt-3 text-center">
    <el-pagination
      class="custom-pagination"
      v-model:currentPage="pagination.page"
      :page-size="pagination.per_page"
      :small="'small'"
      layout="total, sizes, prev, pager, next"
      :total="pagination.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { PaginationModel } from "@/store/models/utils/PaginationModel";

export default {
  name: "Pagination",
  props: {
    pagination: PaginationModel,
  },
  emits: ['onSizeChange', 'onCurrentChange'],
  methods: {
    handleSizeChange(per_page) {
      this.handleQueryPagination(per_page, 'per_page')
      this.$emit('onSizeChange', per_page);
    },
    handleCurrentChange(currentPage) {
      this.handleQueryPagination(currentPage, 'current')
      this.$emit('onCurrentChange', currentPage);
    },
    handleQueryPagination(value, name){ // TODO move this logic to another component to make it more reusable
      const isCurrent = name === 'current'
      const query = this.$route.query
      const queryPagination = {
        per_page: !isCurrent ? value : this.pagination.per_page,
        page: isCurrent ? value : this.pagination.page
      }
      this.$router.push({query: {...query, ...queryPagination}})
    }
  }
};
</script>

<style scoped>
  .custom-pagination {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
</style>
