<template>
  <div>
    <el-form  :model="uploadsFilterForm" class="demo-form-inline" label-width="100px" label-position="left" style="max-width: 600px">
      <el-form-item :label="$t('Wallet')">
        <el-input clearable v-model="uploadsFilterForm.wallet_address" :placeholder="$t('Search by wallet address')" />
      </el-form-item>
      <el-form-item :label="$t('Status')">
        <el-select clearable multiple v-model="uploadsFilterForm.statuses" :placeholder="$t('Select status')">
          <el-option :label="$t('Pending')" :value="UploadStatusEnum.PENDING_APPROVAL" />
          <el-option :label="$t('Approved')" :value="UploadStatusEnum.APPROVED" />
          <el-option :label="$t('Rejected')" :value="UploadStatusEnum.REJECTED" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button class="mr-4" type="primary" @click="handleFilterUploads">{{ $t('Apply') }}</el-button>
        <el-button type="secondary" plain  @click="handleClearFilter">{{ $t('Clear') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { UploadStatusEnum } from "@/store/enums/UploadStatusEnum";

export default {
  name: "UploadsFilter",
  emits: ['onFilterUploads'],
  props: {
    uploadsFilterForm: Object
  },
  data() {
    return {
      UploadStatusEnum
    }
  },
  methods: {
    handleFilterUploads() {
      const filteredFields = Object.keys(this.uploadsFilterForm).filter(itemKey => this.uploadsFilterForm[itemKey]).reduce((obj, key) => {
        obj[key] = this.uploadsFilterForm[key];
        return obj;
      }, {});
      this.$router.push({ query: { ...filteredFields } })
      this.$emit('onFilterUploads');
    },
    handleClearFilter() {
      Object.keys(this.uploadsFilterForm).map(item => {
        this.uploadsFilterForm[item] = ''
      })
      this.$router.push({ query: {} })
      this.$emit('onFilterUploads');
    }
  }
};
</script>

<style scoped>

</style>
