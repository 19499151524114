import store from "@/store";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";

export default function RoleGuard (roles: UserRoleEnum[]){
    return function async (to, from, next) {
        let userRole = store.getters?.currentUser?.role;
        if (!userRole) {
          store.dispatch('fetchAuthUserData').then((data) => {
            userRole = store.getters.currentUser.role;
            handleRoute(store.getters.currentUser.role, roles, next)
          }).catch(err => {
            next({ name: 'sign-in' })
          })
        } else {
            handleRoute(userRole, roles, next)
        }
    }
}

function handleRoute (userRole, routeRoles, next) {
  if(routeRoles.includes(userRole)){
    next()
  }else{
    next({ name: '/admin/dashboard' })
  }
}