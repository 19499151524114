import ApiService from "@/core/services/ApiService";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import { API_BINARY_SERVICE} from "@/router/ApiRoutes";

@Module
export default class BinaryServiceModule extends VuexModule {
  @Action({ rawError: true })
  binaryTreeInjection(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_BINARY_SERVICE.SEED_BINARY_BETWEEN, payload)
        .then(res => {
          resolve(res.status === 200);
        }).catch(err => {
          reject(err);
      })
    })
  }

  @Action({ rawError: true })
  getAllFastStart(payload) {
    const { page, per_page: perPage } = payload
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios({
        method: 'get',
        url: API_BINARY_SERVICE.GET_ALL_FAST_START,
        params: { page, perPage }
      }).then(res => {
        const { data, total } = res.data
          const pagination = {
            page: Number(payload.page),
            per_page: Number(payload.per_page),
            total: Number(total),
            total_pages: Math.ceil(total / payload.per_page),
          }
          resolve({
            data,
            pagination
          });
        }).catch(err => {
        reject(err);
      })
    })
  }

  @Action({ rawError: true })
  getAllQualifiedUsersForDirectSalesIncentive(payload) {
    const { page, per_page: perPage } = payload
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios({
        method: 'get',
        url: API_BINARY_SERVICE.GET_DIRECT_SALES_INCENTIVE_QUALIFIED,
        params: { page, perPage }
      }).then(res => {
        const { data, meta: { total } } = res.data
        const pagination = {
          page: Number(payload.page),
          per_page: Number(payload.per_page),
          total: Number(total),
          total_pages: Math.ceil(total / payload.per_page),
        }
        resolve({
          data,
          pagination
        });
      }).catch(err => {
        reject(err);
      })
    })
  }

  @Action({ rawError: true })
  getTotalDirectSalesIncentiveDistributableRewards() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios({
        method: 'get',
        url: API_BINARY_SERVICE.GET_DIRECT_SALES_INCENTIVE_TOTAL_CASH_REWARDS,
      }).then(res => {
        resolve(res.data.total_cash_rewards);
      }).catch(err => {
        reject(err);
      })
    })
  }

  @Action({ rawError: true })
  getAllCashBonuses(payload) {
    const { page, per_page: perPage, ...filter } = payload
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios({
        method: 'get',
        url: API_BINARY_SERVICE.GET_ALL_CASH_BONUSES,
        params: { page, perPage, ...filter }
      }).then(res => {
        const { data, total } = res.data
        const pagination = {
          page: Number(payload.page),
          per_page: Number(payload.per_page),
          total: Number(total),
          total_pages: Math.ceil(total / payload.per_page),
        }
        resolve({
          data,
          pagination
        });
      }).catch(err => {
        reject(err);
      })
    })
  }

  @Action({ rawError: true })
  binaryUserAddAutoship(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_BINARY_SERVICE.BINARY_USER_ADD_AUTOSHIP, payload)
        .then(res => {
          resolve(res.status === 200);
        }).catch(err => {
        reject(err);
      })
    })
  }

  @Action({ rawError: true })
  binaryUserUpdateRank(payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(API_BINARY_SERVICE.BINARY_USER_UPDATE_RANK, payload)
        .then(res => {
          resolve(res.status === 200);
        }).catch(err => {
        reject(err);
      })
    })
  }

  @Action({ rawError: true })
  async binaryRankStatistics(payload) {
    const { page, perPage } = payload
    ApiService.setHeader();

    const res = await ApiService.vueInstance.axios({
      method: 'get',
      url: API_BINARY_SERVICE.GET_RANK_STATISTICS,
      params: payload
    });

    const { data, sum, meta: {total} } = res.data;
    const pagination = {
      page: Number(page),
      per_page: Number(perPage),
      total: Number(total),
      total_pages: Math.ceil(total / perPage),
    }

    return {
      data,
      sum,
      pagination
    };
  }

  @Action({ rawError: true })
  async thirdAnniversaryIncentiveStatistics() {
    ApiService.setHeader();

    const res = await ApiService.vueInstance.axios({
      method: 'get',
      url: API_BINARY_SERVICE.THIRD_ANNIVERSARY_INCENTIVE_STATISTICS,
    });

    const { data } = res.data;

    return data as {
      totalAmountToBeDistributed: number;
      usersQualifiedTurnovers: {
        qualified_turnover: number;
        user_id: number;
        username: string;
        wallet_address: string;
        reward: number;
      }[];
    };
  }
}
