<template>
  <el-table :data="tableData" style="width: 100%">
    <el-table-column prop="wallet_address" label="User"  />
    <el-table-column prop="balance" label="Amount USD" />
  </el-table>
</template>

<script setup>
import {computed, ref} from "vue";

    const props = defineProps({
      users: Array,
    })

    const tableData = computed(() => props.users)
console.log(tableData);
</script>

<style scoped lang="scss">

</style>
