import { UserModel } from "@/store/models/UserModel";
import {PaymentStatusEnum} from "@/store/enums/PaymentStatusEnum";
import {ERROR_MESSAGES, THROW_ERROR} from "@/core/config/constants";

export default class PaymentsContainer {
  payments = {} as Map<number, PaymentsContainerItem>;

  constructor(data: any[]) {
    data.map(dt => this.payments[dt.id] = new PaymentsContainerItem(dt));
  }

  getPayments(): PaymentsContainerItem[] {
    return Object.values(this.payments).reverse();
  }

  paymentReprocess(paymentId: number): void {
    let item = this.payments[paymentId];
    if (item) {
      item.status = PaymentStatusEnum.CREATED
      item.detectStatusInfo();
    } else {
      THROW_ERROR(ERROR_MESSAGES.ITEM_NOT_FOUND);
    }
  }
}

class PaymentsContainerItem {
  id: number;
  payment_uid: string;
  product_type: string;
  bundle_sc_id: number;
  grant_transaction_hash: string;
  fee_included_amount: number;
  amount_in_busd: number;
  currency: string;
  deviation_amount: number;
  payment_wallet_address: number;
  distrib_tx_hash: string;
  user_wallet_address: string;
  user_id: number;
  user_wallet: string;
  user_email: any;
  created_at: Date;
  status: string | IStatusInfo;

  constructor(obj: any) {
    this.id = obj.id;
    this.payment_uid = obj.payment_uid;
    this.product_type = obj.product_type;
    this.bundle_sc_id = obj.bundle_sc_id;
    this.grant_transaction_hash = obj.grant_transaction_hash;
    this.fee_included_amount = obj.fee_included_amount;
    this.amount_in_busd = obj.amount_in_busd;
    this.deviation_amount = obj.deviation_amount;
    this.payment_wallet_address = obj.payment_wallet_address;
    this.distrib_tx_hash = obj.distrib_tx_hash;
    this.user_wallet_address = obj.user_wallet_address;
    this.currency = obj.currency;
    this.user_id = obj.id
    this.user_wallet = obj.user.wallet_address
    this.user_email = obj.user.email
    this.status = obj.status;
    this.created_at = new Date(obj.created_at);
    this.detectStatusInfo(obj.status);
  }

  detectStatusInfo(statusName): any {
        let statusText = '';
        let statusClassName = '';

        switch (this.status) {
            case PaymentStatusEnum.COMPLETED:
                statusText = 'Completed';
                statusClassName = 'success';
                break;
            case PaymentStatusEnum.EXPIRED:
                statusText = 'Expired';
                statusClassName = 'danger';
                break;
            case PaymentStatusEnum.CREATED:
                statusText = 'Awaiting payment';
                statusClassName = 'primary';
                break;
            default :
                statusText = 'Processing';
                statusClassName = 'warning';
                break;
        }

        this.status =  {
            statusName,
            statusText: statusText,
            statusClassName: statusClassName
        }
    }
}

interface IStatusInfo {
    statusName: string,
    statusText: string,
    statusClassName: string
}