<template>
  <div class="page-content">
    <div>
      <PaymentsFilter :paymentsFilterForm="paymentsFilterForm" @onFilterPayments="handleFilterPayments" />
    </div>
    <div><hr></div>
    <div v-if="paymentsContainer">
      <el-table :data="paymentsContainer.getPayments()" style="width: 100%" class="mt-5 mb-5">
        <el-table-column prop="payment_uid" :label="$t('Payment UID')" width="350" show-overflow-tooltip />
        <el-table-column prop="currency" :label="$t('Currency')" width="100" />
        <el-table-column prop="user_wallet" :label="$t('User wallet')" width="400" >
          <template #default="scope">
            <CurrencyUrl :currency="scope.row.currency" :address-or-tx="scope.row.user_wallet" :type="'address'"  />
          </template>
        </el-table-column>
        <el-table-column prop="user_email" :label="$t('User email')" width="250" show-overflow-tooltip />
        <el-table-column :label="$t('Status')" width="150">
          <template #default="scope">
            <StatusInfo
                :text="scope.row.status.statusText"
                :className="scope.row.status.statusClassName"
            />
          </template>
        </el-table-column>
        <el-table-column prop="created_at" :label="$t('Created at')" width="150">
          <template #default="scope">
            {{ scope.row.created_at.toUTCString() }}
          </template>
        </el-table-column>
        <el-table-column width="80" class="w-100 d-flex justify-content-end" >
          <template #default="scope">
            <el-button class="me-0 d-flex justify-content-end" @click="handleViewModalOpen(scope.row)">
              <span class="el-dropdown-link bank-teams-table--link-icon">
                <i class="bi bi-eye fs-1 fw-bolder"></i>
              </span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <PaymentViewModal
        :popupVisible="!!paymentViewModalData"
        :paymentData="paymentViewModalData"
        @handle-on-close="handleViewModalClose"
        @handle-payment-reprocess="handlePaymentReprocess"
      />
      <Pagination
        v-if="pagination"
        :pagination="pagination"
        @onSizeChange="handleSizeChange"
        @onCurrentChange="handleCurrentChange"
      />
    </div>
    <div v-else>
      <CustomLoader />
    </div>
  </div>
</template>

<script>

import store from "@/store";
import CustomLoader from "@/shared-components/utils/CustomLoader";
import { SweetAlertService } from "@/core/services/SweetAlertService";
import { ERROR_MESSAGES } from "@/core/config/constants";
import StatusInfo from "@/shared-components/utils/StatusInfo";
import PaymentsFilter from "@/pages/gymstreet/payments/PaymentsFilter";
import PaymentsContainer from "@/store/models/PaymentsContainer";
import Pagination from "@/shared-components/utils/Pagination";
import { PaginationModel } from "@/store/models/utils/PaginationModel";
import PaymentViewModal from "./PaymentViewModal";
import CurrencyUrl from "@/shared-components/CurrencyUrl";

export default {
  name: "Payments",
  components: {
    CurrencyUrl,
    PaymentViewModal,
    Pagination,
    PaymentsFilter,
    StatusInfo,
    CustomLoader
  },
  data() {
    return {
      paymentViewModalData: null,
      paymentsContainer: null,
      pagination: {
        page: '',
        per_page: '',
      },
      paymentsFilterForm: {
        payment_uid: '',
        user_wallet: '',
        user_email: '',
        payment_wallet: ''
      },
      pendingActionsIndexes: new Set(),
    };
  },
  async mounted() {
    this.handleQuery()
    await this.fetchPayments()
  },
  methods: {
    async fetchPayments() {
      this.paymentsContainer = null;
      try {
        let paymentsRes = await store.dispatch("fetchPayments", { filters: { ...this.paymentsFilterForm }, pagination: { ...this.pagination } });
        this.paymentsContainer = new PaymentsContainer(paymentsRes.payments);
        this.pagination = new PaginationModel(paymentsRes.pagination);
      } catch (e) {
        console.error(e);
        SweetAlertService.notify(SweetAlertService.notificationTypes.error, ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }
    },
    async handleFilterPayments() {
      this.pagination.page = 1;
      await this.fetchPayments()
    },
    async handleSizeChange(pageSize) {
      this.pagination.per_page = pageSize;
      await this.fetchPayments();
    },
    async handleCurrentChange(currentPage) {
      this.pagination.page = currentPage;
      await this.fetchPayments();
    },
    handleViewModalOpen(item) {
      this.paymentViewModalData = item
    },
    handleViewModalClose() {
      this.paymentViewModalData = null
    },
    handlePaymentReprocess(paymentId){
      this.paymentsContainer.paymentReprocess(paymentId);
    },
    handleQuery(){
      const query = this.$route.query
      this.pagination.page = query.page ? query.page : ''
      this.pagination.per_page =query.per_page ? query.per_page : ''

      this.paymentsFilterForm.payment_uid = query.payment_uid ? query.payment_uid : ''
      this.paymentsFilterForm.payment_wallet = query.payment_wallet ? query.payment_wallet : ''
      this.paymentsFilterForm.user_wallet = query.user_wallet ? query.user_wallet : ''
      this.paymentsFilterForm.user_email = query.user_email ? query.user_email : ''
    }
  }
};
</script>

<style scoped>

</style>
