import ApiService from "@/core/services/ApiService";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import { API_VOTE_SERVICE } from "@/router/ApiRoutes";
import { Campaign } from '../../../pages/gymstreet/elite-vote/Campaign';

@Module
export default class VoteServiceModule extends VuexModule {
  @Action({ rawError: true })
  getAllCampaigns(payload: {page: number, per_page: number}) {
    const { page, per_page: perPage } = payload
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios({
        method: 'get',
        url: API_VOTE_SERVICE.GET_CAMPAIGNS,
        params: { page, perPage }
      }).then(res => {
        const { data, total } = res.data
          const pagination = {
            page: Number(payload.page),
            per_page: Number(payload.per_page),
            total: Number(total),
            total_pages: Math.ceil(total / payload.per_page),
          }
          resolve({
            data,
            pagination
          });
        }).catch(err => {
            reject(err);
      })
    })
  }

  @Action({ rawError: true })
  getCampaign(payload: {id: number}) {
    const { id } = payload
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios({
        method: 'get',
        url: API_VOTE_SERVICE.GET_CAMPAIGN,
        params: { id }
      }).then(res => {
        const { data } = res;
          resolve({
            data
          });
        }).catch(err => {
            reject(err);
      })
    })
  }

  @Action({ rawError: true })
  createCampaign() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios({
        method: 'put',
        url: API_VOTE_SERVICE.CREATE_CAMPAIGN
      }).then(res => {
        const { data } = res;
        resolve({
          ...data
        });
      }).catch(err => {
            reject(err);
      })
    })
  }

  @Action({ rawError: true })
  saveCampaign(payload: {campaign: Campaign, descriptions: any}) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios({
        method: 'post',
        url: API_VOTE_SERVICE.SAVE_CAMPAIGN,
        data: payload
      }).then(res => {
        const { data } = res;
        resolve({
          ...data
        });
      }).catch(err => {
            reject(err);
      })
    })
  }

  @Action({ rawError: true })
  deleteFeature(payload: {campaignId: number, featureId: number}) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios({
        method: 'delete',
        url: API_VOTE_SERVICE.DELETE_FEATURE,
        data: payload
      }).then(res => {
        const { data } = res;
        resolve({
          ...data
        });
      }).catch(err => {
            reject(err);
      })
    })
  }


}
