<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-6 align-self-center">
        <p style="font-size: 18px"><b><span class="text-success" v-if="data.totalTh"> Total TH :
          ({{data.totalTh}})
        </span></b></p>
      </div>
      <div class="col-lg-6 align-self-center justify-content-end">
        <el-date-picker
            v-model="defaultValue"
            type="datetimerange"
            unlink-panels
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            value-format="YYYY-MM-DD HH:mm:ss"
            :shortcuts="shortcuts"
            :size="size"
            :disabledDate="disabledDate"
        />
      </div>
    </div>
    <hr>
    <div class="row mt-5">
      <div class="col-lg-12" >
        <el-tabs v-model="activeName" class="demo-tabs">
          <el-tab-pane label="Users" name="users">
            <el-table :data="data.payments" style="width: 100%">
              <el-table-column prop="wallet_address" label="Wallet"  />
              <el-table-column prop="transaction_hash" label="Transaction Hash" />
              <el-table-column prop="th" label="TH" />
              <el-table-column prop="created_at" label="Created Date">
                <template #default="scope">
                  {{moment(new Date(scope.row.created_at)).format('DD/MM/YYYY HH:mm:ss')}}
                </template>
              </el-table-column>>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>

    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import moment from "moment/moment";
const store = useStore();
const size = ref<'default' | 'large' | 'small'>('small')
const dateNow = new Date()
const dateByDays = (day,currentDate) => currentDate.setTime(currentDate.getTime() - 3600 * 1000 * 24 * day)
const dateToISO = (date) => new Date(date).toISOString().replace('T', ' ').split('.')[0];
const defaultValue = ref([dateToISO(dateByDays(7,new Date())),dateToISO(new Date())])
const data = ref({
  totalTh: 0,
  payments: []
});
const activeName = ref('users')

const shortcuts = [
  {text: 'Today', value: () => [dateNow, dateNow]},
  {text: 'Last week', value: () =>  [dateByDays(7,new Date()),dateNow]},
  {text: 'Last month', value: () => [dateByDays(30,new Date()),dateNow]},
]

const disabledDate = (time) =>{
  return time.getTime() > Date.now() || time.getTime() < new Date('2024-09-02').getTime();
}

onMounted(async () => {
  const [from,to] = defaultValue.value;
  const result  = await store.dispatch('fetchBtcStatistics',{from,to,type:'time'});
  console.log(result);
  data.value.totalTh = result.resultTotal.totalTh;
  data.value.payments = result.payments;
})

watch(defaultValue,async () => {
  const [from,to] = defaultValue.value;
  const result  =  await store.dispatch('fetchBtcStatistics',{from,to,type:'time'});
  data.value.totalTh = result.resultTotal.totalTh;
  data.value.payments = result.payments;
},{immediate: true})
</script>

<style >
.el-range-editor{
  width: 100% !important;
}
</style>

