<template>
  <div :class="'fw-bold status-container bg-' + className" :title="title">
    <div>{{ $t(text) }}</div>
  </div>
</template>

<script>
export default {
  name: "StatusInfo",
  props: {
    text: String,
    className: String,
    title: String | null
  }
};
</script>

<style scoped>
.status-container {
  text-align: center;
  padding: 5px 3px;
  color: #ffffff;
  border-radius: 10px;
}
</style>
