<template>
  <div class="page-content">
    <el-form :model="usersStatisticsForm" :rules="rules"  ref="perUserForm"  class="demo-form-inline" label-width="120px" label-position="right">
      <div class="row">
        <div class="col-lg-4">
          <el-date-picker
              style="width: 100%;height: 40px"
              v-model="usersStatisticsForm.range"
              type="daterange"
              align="right"
              size="small"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              value-format="YYYY-MM-DD"
              :default-value="new Date().toISOString().split('T')[0]"
              :disabled-date="disabledDate">
          </el-date-picker>
        </div>
        <div class="col-lg-2">
          <el-button class="mr-4" type="primary" @click="usersStatisticsFormSubmit">{{ $t('Apply') }}</el-button>
        </div>
        <div class="col-lg-3">
          <div class="result_title">{{$t('Total used points')}}</div>
          <div class="result_value mt-1">
            {{statistics.papPurchase ? statistics.papPurchase.toFixed(2) : 0}}
          </div>
        </div>
      </div>
    </el-form>
    <div><hr></div>
    <div class="row">
      <div class="col-lg-3">
        <div class="mb-4 ml-3">
          <div class="result_title">{{$t('Total available points')}}</div>
          <div class="result_value mt-1">
            {{statistics.totalAvailable ? statistics.totalAvailable.toFixed(2) : 0}}
          </div>
        </div>
      </div>
    </div>
    <div><hr></div>
    <div v-if="statistics.topUsers">
      <div>
        <div class="result_title">{{$t('Top 10 users')}}</div>
      </div>
      <el-table :data="statistics.topUsers" style="width: 100%" class="mt-5 mb-5">
        <el-table-column prop="wallet_address" :label="$t('Wallet address')"  show-overflow-tooltip  />
        <el-table-column prop="user_username" :label="$t('Username')"  show-overflow-tooltip  />
        <el-table-column prop="spb_balance" :label="$t('Pap points')"  show-overflow-tooltip :formatter="formatter"  />
      </el-table>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Charts from "@/pages/gymstreet/users/Charts.vue";

export default {
  name: "PapStatistics",
  data() {
    return {
      statistics:[],
      usersStatisticsForm: {
        range: [new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]
          ,new Date().toISOString().split('T')[0]],
      },
    };
  },

  async mounted() {
    const [from,to]=this.usersStatisticsForm.range;
    const data = {from, to}
    console.log(data);
    this.statistics = await store.dispatch("fetchPapStatistics", data);
    console.log(this.statistics);
  },

  methods: {
    async usersStatisticsFormSubmit() {
      const [from,to]=this.usersStatisticsForm.range;
      const data = {from, to}
      this.statistics = await store.dispatch("fetchPapStatistics", data);
    },
    disabledDate(time) {
      return time.getTime() > Date.now() || time.getTime() < new Date('2023-09-14').getTime();
    },
    formatter(row, column) {
      return row.spb_balance.toFixed(2);
    },
  }
};
</script>

<style scoped lang="scss">
  :deep(.el-form-item__content)
  {
    margin-left: 0 !important;
  }
  .result_title{
    font-size: 16px;
    font-weight: 500;
  }
  .result_value{
    font-size: 21px;
    font-weight: bold;
  }
</style>
