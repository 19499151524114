import { UserModel } from "@/store/models/UserModel";
import { UploadStatusEnum } from "@/store/enums/UploadStatusEnum";
import { ERROR_MESSAGES, THROW_ERROR } from "@/core/config/constants";

export default class UploadContainer {
  uploads = {} as Map<number, UploadContainerItem>;

  constructor(data: any[]) {
    data.map(dt => this.uploads[dt.id] = new UploadContainerItem(dt));
  }

  getUploads(): UploadContainerItem[] {
    return Object.values(this.uploads).reverse();
  }

  approveImage(imageId: number): void {
    let item = this.uploads[imageId];
    if (item) {
      item.status = UploadStatusEnum.APPROVED;
      item.detectStatusInfo();
    } else {
      THROW_ERROR(ERROR_MESSAGES.ITEM_NOT_FOUND);
    }
  }

  rejectImage(imageId: number): void {
    let item = this.uploads[imageId];
    if (item) {
      item.status = UploadStatusEnum.REJECTED;
      item.detectStatusInfo();
    } else {
      THROW_ERROR(ERROR_MESSAGES.ITEM_NOT_FOUND);
    }
  }
}

class UploadContainerItem {
  id: number;
  status: number;
  updatedAt: Date;
  uploadedAt: Date;
  url: string;
  uploadedBy: UserModel;
  statusInfo: IStatusInfo = {} as IStatusInfo;

  constructor(obj: any) {
    this.id = obj.id;
    this.status = obj.status;
    this.updatedAt = new Date(obj.updated_at);
    this.uploadedAt = new Date(obj.uploaded_at);
    this.url = obj.url;
    this.uploadedBy = new UserModel(obj.user);
    this.detectStatusInfo();
  }

  detectStatusInfo(): any {
    let statusText = '';
    let statusClassName = '';

    switch (this.status) {
      case UploadStatusEnum.PENDING_APPROVAL:
        statusText = 'Pending for approval';
        statusClassName = 'secondary';
        break;
      case UploadStatusEnum.APPROVED:
        statusText = 'Approved';
        statusClassName = 'success';
        break;
      case UploadStatusEnum.REJECTED:
        statusText = 'Rejected';
        statusClassName = 'danger';
        break;
    }

    this.statusInfo =  {
      statusText: statusText,
      statusClassName: statusClassName
    }
  }
}

interface IStatusInfo {
  statusText: string,
  statusClassName: string
}

