<template>
  <div class="page-content">
    <el-form :model="usersStatisticsForm" :rules="rules"  ref="perUserForm"  class="demo-form-inline" label-position="right">
      <div class="row">
        <div class="col-lg-6">
          <el-form-item  prop="identifier">
            <el-input clearable v-model.trim="usersStatisticsForm.identifier" @change="valueChange" prop="identifier" :placeholder="$t('Search by Username or Wallet Address')" />
          </el-form-item>
        </div>
        <div class="col-lg-2">
          <el-button class="mr-4" type="primary" @click="usersStatisticsFormSubmit">{{ $t('Apply') }}</el-button>
        </div>
        <div class="col-lg-2 offset-2">
          <div class="result_title">Total Distributed</div>
          <div class="result_value mt-1">
            {{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }) .format(statistics.sum)}}
           </div>
        </div>

      </div>
    </el-form>
    <div><hr></div>
    <div v-if="statistics">
      <el-table :data="statistics.result" style="width: 100%" class="mt-5 mb-5">
        <el-table-column prop="rewardedRank" label="Rank" :min-width="10"></el-table-column>
        <el-table-column prop="txHash" label="Txhash" :min-width="40"></el-table-column>
        <el-table-column prop="usdtBonus" label="Amount" :min-width="10" :formatter="formatterAmount"></el-table-column>
        <el-table-column prop="ignored" label="Ignored" :min-width="10" :formatter="formatterIgnored" ></el-table-column>
        <el-table-column prop="createdAt" label="Date" :min-width="10" :formatter="formatterDate" ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import Charts from "@/pages/gymstreet/users/Charts.vue";

  export default {
    name: "PerUser",
    components: {Charts},
    data() {
      return {
        rules: {
          identifier: [
            { required: true, message: 'Please input identifier', trigger: 'blur' },
          ],
        },
        statistics:{
          result:[],
          sum:0,
        },
        usersStatisticsForm: {
          identifier: '',
        },
      };
    },

    async mounted() {
      this.statistics.sum = await store.dispatch("fetchCashPromoStatistics", {});
    },

    methods: {
     async usersStatisticsFormSubmit() {
       const valid = await this.$refs.perUserForm.validate();
        if (!valid) return;
       const data = {identifier: this.usersStatisticsForm.identifier}
       this.statistics.result = await store.dispatch("fetchCashPromoStatistics", data);
     },
      valueChange(){
        this.statistics.result = []
      },
      formatterIgnored(row, column){
        return row.ignored ? 'Yes' : 'No'
      },
      formatterAmount(row, column){
        return row.usdtBonus * 10/7
      },
      formatterDate(row, column){
        return new Date(row.createdAt).toISOString().split('T')[0]
      }

    }
  };
</script>

<style scoped lang="scss">
  .result_title{
    font-size: 15px;
    font-weight: 500;
  }
  .result_value{
    font-size: 21px;
    font-weight: bold;
    color: green;
  }
  .outerContainer, .innerContainer {
    max-width: 100%;
  }
</style>
