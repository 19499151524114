<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-4 align-self-center">
        <p style="font-size: 18px"><b><span class="text-success" v-if="totalRemainingValue"> Total Purchase amount :
          ({{(totalRemainingValue.totalPurchase).toFixed(2)}} $)
        </span></b></p>
      </div>
      <div class="col-lg-3 align-self-center">
        <p style="font-size: 18px"><b><span class="text-success" v-if="totalRemainingValue"> Total Claimed BTC :
          ({{totalRemainingValue.totalClaimed}})
        </span></b></p>
      </div>
      <div class="col-lg-2 align-self-center">
        <p style="font-size: 18px"><b><span class="text-success" v-if="totalRemainingValue"> Total TH :
          ({{totalRemainingValue.totalTh}})
        </span></b></p>
      </div>
      <div class="col-lg-3 align-self-center">
        <el-date-picker
            v-model="defaultValue"
            type="daterange"
            unlink-panels
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            value-format="YYYY-MM-DD"
            :shortcuts="shortcuts"
            :size="size"
            :disabledDate="disabledDate"
        />
      </div>
    </div>
    <hr>
    <div class="row mt-5">
      <div class="col-lg-12" >
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="Users" name="users">
            <div class="row">
              <div class="col-lg-6">
                <el-form-item label="Wallet Address">
                  <el-input  v-model="walletAddress" placeholder="Wallet Address" @input="filter"></el-input>
                </el-form-item>
              </div>
            </div>

            <el-table :data="users.data" style="width: 100%">
              <el-table-column prop="wallet_address" label="User"  />
              <el-table-column prop="total_th" label="Total TH" />
              <el-table-column prop="payments" label="Total purchase USD"/>
            </el-table>
            <el-pagination  v-if="users.totalCount > 25" background layout="prev, pager, next"
                           :total="Number(users.totalCount)"
                           :page-size="perPage"
                           v-model:current-page = "pageUsers"
                           @current-change="changeUsers"
            />
          </el-tab-pane>
          <el-tab-pane label="Weekly Distribution" name="weekly">
            <div class="mt-5 text-center ">
              <el-table :data="weekly.data" style="width: 100%">
                <el-table-column prop="id" label="Week"  />
                <el-table-column prop="totalUSDTAmount" label="Total Distributed BTC"/>
                <el-table-column prop="date" label="Date">
                  <template #default="scope">
                    {{moment(new Date(scope.row.date)).format('DD/MM/YYYY')}}
                  </template>
                </el-table-column>>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import moment from "moment/moment";
const store = useStore();
const totalRemainingValue= ref(0);
const pageUsers = ref(1);
const pageWeekly = ref(1);
const perPage = ref(25);
const size = ref<'default' | 'large' | 'small'>('small')
const dateNow = new Date()
const dateToISO = (date) => new Date(date).toISOString().split('T')[0]
const defaultValue = ref([dateToISO(new Date('2024-09-02')),dateToISO(new Date())])
const weekly = ref({
  totalCount: 0,
  data: []
});
const users = ref({
  totalCount: 0,
  data: []
});
const activeName = ref('users')

const walletAddress = ref('')
const  changePage =  async (page,type = 'users',walletAddress=null)=>{
  const typeName = type === 'users' ? 'users' : 'weekly';
  const payload = {type:typeName,page: page.value,perPage: perPage.value};
  if(walletAddress) payload.walletAddress = walletAddress;
  const resultOrigin = await store.dispatch('fetchBtcUserStatistics', payload);
  const result = {};
   result['data'] = resultOrigin.data.map(item=> {
    return {
      ...item,
      payments: item.payments ? item.payments.reduce((acc,cur) => acc +cur.total ,0) : 0
    }
  })
  result['totalCount'] = resultOrigin.totalCount;
  if(type === 'users') {
    users.value.data = result.data;
    users.value.totalCount = result.totalCount;
  } else {
    weekly.value.data = result.data;
    weekly.value.totalCount = result.totalCount;
  }
}
const dateByDays = (day,currentDate) => currentDate.setTime(currentDate.getTime() - 3600 * 1000 * 24 * day)
const shortcuts = [
  {text: 'Today', value: () => [dateNow, dateNow]},
  {text: 'Last week', value: () =>  [dateByDays(7,new Date()),dateNow]},
  {text: 'Last month', value: () => [dateByDays(30,new Date()),dateNow]},
]

const filter = async () => {
  if(walletAddress.value.length === 42 ) {
    await changePage(pageUsers,'users',walletAddress.value)
  }
}
const changeUsers = async (page) => {
  pageUsers.value = page;
  await changePage(pageUsers)
}

const changeWeekly = async (page) => {
  pageWeekly.value = page;
  await changePage(pageWeekly,'weekly')
}

const handleClick =  async (tab, event) => {
    walletAddress.value = '';
  if(tab.props.name === 'users') {
    await changePage(pageUsers)
  } else {
    await changePage(pageWeekly,'weekly')
  }
}

const disabledDate = (time) =>{
  return time.getTime() > Date.now() || time.getTime() < new Date('2024-09-02').getTime();
}

onMounted(async () => {
  const [from,to] = defaultValue.value;
  totalRemainingValue.value =  await store.dispatch('fetchBtcStatistics',{from,to});
  await changePage(pageUsers)
})

watch(defaultValue,async () => {
  const [from,to] = defaultValue.value;
  totalRemainingValue.value =  await store.dispatch('fetchBtcStatistics',{from,to});
},{immediate: true})
</script>

<style >
  .el-range-editor{
    width: 100% !important;
  }
</style>

