<template>
  <div class="page-content">
    <div>
      <UsersFilter :usersFilterForm="usersFilterForm" @onFilterUsers="handleFilterUsers" />
    </div>
    <div><hr></div>
    <div v-if="usersContainer">
      <el-table :data="usersContainer.getUsers()" style="width: 100%" class="mt-5 mb-5">
        <el-table-column prop="id" :label="$t('ID')" width="100" show-overflow-tooltip />
        <el-table-column prop="email" :label="$t('Email')" width="250" />
        <el-table-column prop="username" :label="$t('Username')" width="100" />
        <el-table-column prop="wallet_address" :label="$t('Wallet address')" width="400" />
        <el-table-column prop="is_fully_web2" :label="$t('Type')" width="100">
          <template #default="scope">
            {{ scope.row.is_fully_web2 ? 'web_2' : 'web_3' }}
          </template>
        </el-table-column>
        <el-table-column v-if="hasAccess" width="650">
          <template #default="scope" >
            <el-button size="small"  type="primary"  @click="handleViewModalOpen(scope.row.id)"> {{ $t('Details') }}</el-button>
            <el-button size="small"  v-if="isSuperadmin" type="primary"  @click="handleAddAutoshipModalOpen(scope.row.wallet_address)"> {{ $t('Add autoship') }}</el-button>
            <el-button size="small" type="primary"  @click="handleEditModalOpen({...scope.row})"> {{ $t('Update') }}</el-button>
            <el-button size="small" type="primary" @click="handleImpersonation({...scope.row})"> {{ $t('Impersonate') }}</el-button>
            <el-button size="small" type="primary" @click="handleAddNotification({...scope.row})">
              {{scope.row.notification_type ? $t('Change notification') : $t('Add notification')}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <UserEditModal
          v-if="hasAccess && userEditModalData"
          :popupVisible="!!userEditModalData"
          :userEditForm="userEditModalData"
          @handle-on-close="handleEditModalClose"
          @handle-update-user="handleUpdateUser"
      />
      <UserAddAutoship
          v-if="isSuperadmin && userAddAuthoshipModalData"
          :popupVisible="!!userAddAuthoshipModalData"
          :addAutoshipFrom="userAddAuthoshipModalData"
          @handle-on-close="handleAddAutoshipModalClose"
      />
      <UserViewModal
          :popupVisible="!!userViewModalData"
          :userId="userViewModalData"
          @handle-on-close="handleViewModalClose"
      />
      <UserAddNotification
          :popupVisible="!!userAddNotification"
          :userId="userAddNotification"
          @handle-on-close="handleAddNotificationClose"
          @handle-get-users="fetchUsers"
          :type = "notificationType"
      />
      <Pagination
        v-if="pagination"
        :pagination="pagination"
        @onSizeChange="handleSizeChange"
        @onCurrentChange="handleCurrentChange"
      />
    </div>
    <div v-else>
      <CustomLoader />
    </div>
  </div>
</template>

<script>

import store from "@/store";
import CustomLoader from "@/shared-components/utils/CustomLoader";
import { SweetAlertService } from "@/core/services/SweetAlertService";
import { ERROR_MESSAGES } from "@/core/config/constants";
import Pagination from "@/shared-components/utils/Pagination";
import UserContainer from "@/store/models/UserContainer";
import { PaginationModel } from "@/store/models/utils/PaginationModel";
import UsersFilter from "./UsersFilter";
import UserViewModal from "./UserViewModal";
import UserAddNotification from "./UserAddNotification";
import UserEditModal from "./UserEditModal";
import { computed } from "vue";
import { UserRoleEnum } from "../../../store/enums/UserRoleEnum";
import UserAddAutoship from "./UserAddAutoship";
import userAddNotification from "@/pages/gymstreet/users/UserAddNotification.vue";

export default {
  name: "Users",
  computed: {
    userAddNotification() {
      return userAddNotification
    }
  },
  components: {
    UserAddAutoship,
    UserEditModal,
    UserViewModal,
    UsersFilter,
    Pagination,
    CustomLoader,
    UserAddNotification
  },
  data() {
    return {
      userEditModalData: null,
      userAddAuthoshipModalData: null,
      userViewModalData: null,
      userAddNotification: null,
      notificationType: null,
      usersContainer: null,
      pagination: {
        page: '',
        per_page: '',
      },
      usersFilterForm: {
        id: '',
        wallet: '',
        username: '',
        cardholder_id: '',
        email: ''
      },
      pendingActionsIndexes: new Set(),
    };
  },
  setup() {
    const hasAccess = computed(() => {
      const accessibleRoles = [
        UserRoleEnum.SUPERADMIN,
        UserRoleEnum.ADMIN,
        UserRoleEnum.MANAGER
      ]
      return accessibleRoles.includes(store.getters.currentUser.role);
    });
    const isSuperadmin = store.getters.currentUser.role === UserRoleEnum.SUPERADMIN;

    return { hasAccess, isSuperadmin }
  },
  async mounted() {
    this.handleQuery()
    await this.fetchUsers()
  },
  methods: {
    async fetchUsers() {
      this.usersContainer = null;
      try {
        let usersRes = await store.dispatch("fetchUsers", { filters: { ...this.usersFilterForm }, pagination: { ...this.pagination } });
        this.usersContainer = new UserContainer(usersRes.users);
        this.pagination = new PaginationModel(usersRes.pagination);
      } catch (e) {
        console.error(e);
        SweetAlertService.notify(SweetAlertService.notificationTypes.error, ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }
    },
    async handleFilterUsers() {
      this.pagination.page = 1;
      await this.fetchUsers()
    },
    async handleSizeChange(pageSize) {
      this.pagination.per_page = pageSize;
      await this.fetchUsers();
    },
    async handleCurrentChange(currentPage) {
      this.pagination.page = currentPage;
      await this.fetchUsers();
    },
    handleEditModalOpen(item) {
      this.userEditModalData = {
        id: item.id,
        email: item.email
      }
    },

    handleAddNotification(item) {
      this.userAddNotification = item.id
      this.notificationType = item.notification_type
    },

    handleAddNotificationClose() {
      this.userAddNotification = null
    },
    handleEditModalClose() {
      this.userEditModalData = null
    },
    handleAddAutoshipModalOpen(walletAddress) {
      this.userAddAuthoshipModalData = {
        walletAddress
      }
    },
    handleAddAutoshipModalClose() {
      this.userAddAuthoshipModalData = null
    },
    handleViewModalOpen(id) {
      this.userViewModalData = id
    },
    handleViewModalClose() {
      this.userViewModalData = null
    },
    handleUpdateUser({id, email}) {
      this.usersContainer.updateEmail(id, email);
    },
    async handleImpersonation({id, email, wallet_address}) {
      const param = id ? 'id' : email ? 'email' : wallet_address ? 'walletAddress' : null;
      const value = id ? id : email ? email : wallet_address ? wallet_address : null;
      const { token } = await store.dispatch('getImpersonationToken', {
        [param]: value
      });
      window.open(`${process.env.VUE_APP_GYMSTREET_URL}/auth/one-time-token?token=${token}`, '_blank');
    },
    handleQuery(){
      const query = this.$route.query
      this.pagination.page = query.page || ''
      this.pagination.per_page =query.per_page || ''

      this.usersFilterForm.id = query.id || ''
      this.usersFilterForm.username = query.username || ''
      this.usersFilterForm.cardholder_id = query.cardholder_id || ''
      this.usersFilterForm.wallet = query.wallet || ''
      this.usersFilterForm.email = query.email || ''
    }
  }
};
</script>

<style scoped>

</style>
