<template>
<div>
  <apexchart type="line" height="450" :options="chartOptions" :series="series"></apexchart>
</div>
</template>

<script setup >

import {PRODUCTS} from "@/core/config/constants";
import {computed, watch} from "vue";

const props = defineProps({
  products: Array,
})
const getProductAmount = (productId) => {
  const [product] = props.products.filter(product => Number(product.productId) === productId)
  return product ? product.totalQuantity.toFixed(2) : 0
}

const series = computed(() => [
  {
    name: "Sales",
    data: Object.keys(PRODUCTS).map(productId => getProductAmount(Number(productId)))
  },
])

const chartOptions = {
  chart: {
    type: 'line',
    zoom: {
      enabled: false
    }
  },
  dataLabels: {
    enabled: false
  },
  title: {
    text: 'Product statistics',
    align: 'left'
  },
  xaxis: {
    labels: {
      show: true,
      rotate: -75,
      rotateAlways: true,
      showDuplicates: false,
      trim: false,
      minHeight: 160,
      style: {
        colors: [],
        fontSize: '10px',
      },
    },
    categories: Object.values(PRODUCTS)
  },
  grid: {
    borderColor: '#f1f1f1',
  }
}


</script>

<style scoped lang="scss">

</style>
