import Auth from "@/router/middleware/Auth";
import RoleGuard from "@/router/middleware/RoleGuard";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";

const binaryServiceRoutes = [
  // {
  //   path: "/binary-service/tree-injection",
  //   name: "/binary-service/tree-injection",
  //   component: () => import("@/pages/binary-service/tree-injection/TreeInjection.vue"),
  //   beforeEnter: [ RoleGuard([UserRoleEnum.SUPERADMIN]) ]
  // },
  {
    path: "/binary-service/fast-start",
    name: "/binary-service/fast-start",
    component: () => import("@/pages/binary-service/fast-start/FastStart.vue"),
    beforeEnter: [ RoleGuard([UserRoleEnum.SUPERADMIN]) ]
  },
  {
    path: "/binary-service/direct-sales-incentive",
    name: "/binary-service/direct-sales-incentive",
    component: () => import("@/pages/binary-service/direct-sales-incentive/DirectSalesIncentive.vue"),
    beforeEnter: [ RoleGuard([UserRoleEnum.SUPERADMIN]) ]
  },
  {
    path: "/binary-service/super-cash-bonus",
    name: "/binary-service/super-cash-bonus",
    component: () => import("@/pages/binary-service/cash-bonus/CashBonus.vue"),
    beforeEnter: [ RoleGuard([UserRoleEnum.SUPERADMIN]) ]
  },
  {
    path: "/binary-service/rank-statistics",
    name: "/binary-service/rank-statistics",
    component: () => import("@/pages/binary-service/rank-statistics/RankStatistics.vue"),
    beforeEnter: [ RoleGuard([UserRoleEnum.SUPERADMIN]) ]
  },
  {
    path: "/binary-service/third-anniversary-incentive",
    name: "/binary-service/third-anniversary-incentive",
    component: () => import("@/pages/binary-service/third-anniversary-incentive/ThirdAnniversaryIncentive.vue"),
    beforeEnter: [ RoleGuard([UserRoleEnum.SUPERADMIN]) ]
  },
];

binaryServiceRoutes.forEach((route: any) => {
  const roleGuards = route.beforeEnter && route.beforeEnter.length ? route.beforeEnter : []
  route['beforeEnter'] = [ Auth, ...roleGuards  ]
});

export default binaryServiceRoutes;
