import { Module, VuexModule } from "vuex-module-decorators";

@Module
export default class StaticDataModule extends VuexModule {
  get supportedLocales() {
    return [
      // {
      //   key: 'en',
      //   flag: "/media/flags/united-states.svg",
      //   name: "English",
      // },
      // {
      //   key: 'fr',
      //   flag: "/media/flags/france.svg",
      //   name: "French",
      // },
    ]
  }
}
