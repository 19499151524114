<template>
  <a :href="getCurrencyUrl(currency, addressOrTx, type)"
     target="_blank"
     rel="noreferrer"
  >
    {{ addressOrTx }}
  </a>
</template>

<script>
import { getCurrencyUrl } from "@/core/helpers/GlobalHelper"

export default {
  name: "CurrencyUrl",
  props: {
    currency: {
      type: String,
      required: true,
    },
    addressOrTx :{
      type: String,
      required: true
    },
    type: {
      type: String,
      validator: (value) => {
        return value === 'address' || value === 'tx'
      }
    }
  },
  data () {
    return {
      getCurrencyUrl
    }
  }
}
</script>