import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { redirectToRouteWithName } from "@/core/helpers/UrlHelper";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL + process.env.VUE_APP_VERSION;

    ApiService.vueInstance.axios.defaults.headers.common[
      "x-api-key"
      ] = `${process.env.VUE_APP_X_API_KEY}`;

    // ApiService.vueInstance.axios.defaults.headers.common[
    //   "user-vers"
    //   ] = `${process.env.VUE_APP_CLIENT_KEY}`;

    ApiService.vueInstance.axios.interceptors.response.use(
      response => response,
      error => this.handleError(error)
    );
  }

  public static async handleError(error) {
    // TODO add handle functionality

    console.log(error.response);
    switch (error.response.status) {
      case undefined:
      case 401:
        console.log(error.response);

        store.commit(Mutations.PURGE_AUTH);
        redirectToRouteWithName('sign-in');
        return Promise.reject(error.response.data.error);
        break;
      case 500:
        console.error('HANDLE ERROR STATUS = ', error.response.status);
        break;
      default:
        console.error('HANDLE ERROR STATUS = ', error.response.status)
        return Promise.reject(error)
    }
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
      ] = `${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  public static setMultipartHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
      ] = `${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] =
      "multipart/form-data";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param config
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`, config);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig | any
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
