import {UserRoleEnum} from "@/store/enums/UserRoleEnum";

export const AdminMenuItems = [
  {
    translationKey: 'Dashboard',
    route: "/",
    pages: [
      {
        sectionTitle: "GymStreet",
        translationKey: 'GymStreet',
        route: "/gymstreet",
        svgIcon: "/media/icons/duotune/abstract/abs004.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Uploads",
            translationKey: 'Uploads',
            route: "/gymstreet/uploads",
            roles: [UserRoleEnum.SUPERADMIN, UserRoleEnum.ADMIN, UserRoleEnum.MANAGER, UserRoleEnum.CUSTOMER_SUPPORT],
          },
          {
            heading: "Payments",
            translationKey: 'Payments',
            route: "/gymstreet/payments",
            roles: [UserRoleEnum.SUPERADMIN, UserRoleEnum.ADMIN, UserRoleEnum.MANAGER, UserRoleEnum.CUSTOMER_SUPPORT],
          },
          {
            heading: "Users",
            translationKey: 'Users',
            route: "/gymstreet/users",
            roles: [UserRoleEnum.SUPERADMIN, UserRoleEnum.ADMIN, UserRoleEnum.MANAGER, UserRoleEnum.CUSTOMER_SUPPORT],
          },
          {
            heading: "Last Card Users",
            translationKey: 'Last Card Users',
            route: "/gymstreet/card-users",
            roles: [UserRoleEnum.SUPERADMIN, UserRoleEnum.ADMIN, UserRoleEnum.CUSTOMER_SUPPORT],
          },
          {
            heading: "Maintenance",
            translationKey: 'Maintenance',
            route: "/gymstreet/maintenances",
            roles: [UserRoleEnum.SUPERADMIN]
          },
          {
            heading: "Analytics",
            translationKey: 'Analytics',
            route: "/gymstreet/analytics",
            roles: [UserRoleEnum.SUPERADMIN]
          },
          {
            heading: "Per User",
            translationKey: 'PerUser',
            route: "/gymstreet/per-user",
            roles: [UserRoleEnum.SUPERADMIN]
          },
          {
              heading: "Cash Promo",
              translationKey: 'Cash Promo',
              route: "/gymstreet/cash-promo",
              roles: [UserRoleEnum.SUPERADMIN]
          },
          {
            heading: "PAP Statistics",
            translationKey: 'PAP Statistics',
            route: "/gymstreet/pap-statistics",
            roles: [UserRoleEnum.SUPERADMIN]
          },
        ],
      },
      {
        sectionTitle: "Binary Service",
        translationKey: 'Binary Service',
        route: "/binary-service",
        svgIcon: "/media/icons/duotune/abstract/abs004.svg",
        fontIcon: "bi-archive",
        sub: [
          // {
          //   heading: "Tree Injection",
          //   translationKey: 'Tree Injection',
          //   route: "/binary-service/tree-injection",
          //   roles: [UserRoleEnum.SUPERADMIN],
          // },
          {
            heading: "Direct sales incentive",
            translationKey: 'Direct sales incentive',
            route: "/binary-service/direct-sales-incentive",
            roles: [UserRoleEnum.SUPERADMIN, UserRoleEnum.ADMIN, UserRoleEnum.MANAGER, UserRoleEnum.CUSTOMER_SUPPORT],
          },
          {
            heading: "Fast start",
            translationKey: 'Fast start',
            route: "/binary-service/fast-start",
            roles: [UserRoleEnum.SUPERADMIN, UserRoleEnum.ADMIN, UserRoleEnum.MANAGER, UserRoleEnum.CUSTOMER_SUPPORT],
          },
          {
            heading: "Super cash bonus",
            translationKey: 'Super cash bonus',
            route: "/binary-service/super-cash-bonus",
            roles: [UserRoleEnum.SUPERADMIN, UserRoleEnum.ADMIN, UserRoleEnum.MANAGER, UserRoleEnum.CUSTOMER_SUPPORT],
          },
          {
            heading: "Rank statistics",
            translationKey: 'Rank statistics',
            route: "/binary-service/rank-statistics",
            roles: [UserRoleEnum.SUPERADMIN],
          },
          {
            heading: "Third Anniversary Incentive",
            translationKey: 'Third Anniversary Incentive',
            route: "/binary-service/third-anniversary-incentive",
            roles: [UserRoleEnum.SUPERADMIN],
          },
        ],
      },
      {
        sectionTitle: "GymNet",
        translationKey: 'GymNet',
        route: "/gymnet",
        svgIcon: "/media/icons/duotune/abstract/abs004.svg",
        fontIcon: "bi-archive",
        sub: [

        ],
      },
      {
        sectionTitle: "Statistics",
        translationKey: 'Statistics',
        route: "/statistics",
        svgIcon: "/media/icons/duotune/abstract/abs004.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Products",
            translationKey: 'Products',
            route: "/statistics/products",
            roles: [UserRoleEnum.SUPERADMIN],
          },
          {
            heading: "Filecoin",
            translationKey: 'Filecoin',
            route: "/statistics/filecoin",
            roles: [UserRoleEnum.SUPERADMIN],
          },
          {
            heading: "BTC",
            translationKey: 'BTC',
            route: "/statistics/btc",
            roles: [UserRoleEnum.SUPERADMIN],
          },
          {
            heading: "BTC by time",
            translationKey: 'BTC by time',
            route: "/statistics/btc-by-time",
            roles: [UserRoleEnum.SUPERADMIN],
          },
          {
            heading: "Moonberg",
            translationKey: 'Moonberg',
            route: "/statistics/moonberg",
            roles: [UserRoleEnum.SUPERADMIN],
          }
        ],
      },
      {
        sectionTitle: "Websites",
        translationKey: 'Websites',
        route: "/websites",
        svgIcon: "/media/icons/duotune/abstract/abs004.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            sectionTitle: "Gymstreet",
            translationKey: 'Gymstreet',
            route: "/websites/gymstreet",
            sub: [
              {
                heading: "Blog",
                translationKey: 'Blog',
                route: "/websites/gymstreet/blog",
                roles: [UserRoleEnum.SUPERADMIN, UserRoleEnum.ADMIN, UserRoleEnum.MANAGER, UserRoleEnum.CUSTOMER_SUPPORT],
              }
            ]
          },
          {
            sectionTitle: "Gymnet",
            translationKey: 'Gymnet',
            route: "/websites/gymnet",
            roles: [UserRoleEnum.SUPERADMIN, UserRoleEnum.ADMIN, UserRoleEnum.MANAGER, UserRoleEnum.CUSTOMER_SUPPORT],
          }
        ],
      },

    ]
  },
]
