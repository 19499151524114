<template>
  <div>
    <el-form :model="paymentsFilterForm" class="demo-form-inline"  label-width="150px" label-position="left" style="max-width: 600px">
      <el-form-item :label="$t('Payment UID')">
        <el-input clearable v-model="paymentsFilterForm.payment_uid" :placeholder="$t('Search by payment uid')" />
      </el-form-item>
      <el-form-item :label="$t('Payment wallet (QR)')">
        <el-input clearable v-model="paymentsFilterForm.payment_wallet" :placeholder="$t('Search by payment wallet')" />
      </el-form-item>
      <el-form-item :label="$t('User wallet')">
        <el-input clearable v-model="paymentsFilterForm.user_wallet" :placeholder="$t('Search by user wallet')" />
      </el-form-item>
      <el-form-item :label="$t('User email')">
        <el-input clearable v-model="paymentsFilterForm.user_email" :placeholder="$t('Search by user email')" />
      </el-form-item>
      <el-form-item>
        <el-button class="mr-4" type="primary" @click="triggerFilterPayments">{{ $t('Apply') }}</el-button>
        <el-button type="secondary" plain  @click="handleClearFilter">{{ $t('Clear') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "PaymentsFilter",
  emits: ['onFilterPayments'],
  props: {
    paymentsFilterForm: Object
  },
  methods: {
    triggerFilterPayments() {
      const filteredFields = Object.keys(this.paymentsFilterForm).filter(itemKey => this.paymentsFilterForm[itemKey]).reduce((obj, key) => {
        obj[key] = this.paymentsFilterForm[key];
        return obj;
      }, {});
      this.$router.push({ query: { ...filteredFields } })
      this.$emit('onFilterPayments');
    },
    handleClearFilter() {
      Object.keys(this.paymentsFilterForm).map(item => {
        this.paymentsFilterForm[item] = ''
      })
      this.$router.push({ query: {} })
      this.$emit('onFilterPayments');
    }
  }
};
</script>

<style scoped>

</style>
