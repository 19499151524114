import { createI18n } from "vue-i18n/index";
import { english } from "@/core/plugins/languages/en";

const messages = {
  en: english
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
