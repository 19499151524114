import auth from "@/router/middleware/Auth";

const adminRoutes = [
  {
    path: "/admin/dashboard",
    name: "/admin/dashboard",
    component: () => import("@/pages/admin/AdminDashboard.vue"),
    beforeEnter: [ auth ]
  },
];

export default adminRoutes;
