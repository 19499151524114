
import {defineComponent} from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import store from "@/store";

export default defineComponent({
  name: "KTHeader",
  props: {
    breadcrumbs: Object,
  },
  components: {
    KTTopbar
  },
  data() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay
    }
  }
});
