<template>
  <el-dialog
    v-model="isVisible"
    width="500px"
    align-center
    @close="handleOnClose"
    :align-center="true"
  >
    <div class="text-center">
      <el-form :model="userEditForm" label-width="60px" class="demo-form-inline">
        <el-form-item :label="$t('Id')">
          <el-input v-model="userEditForm.id" disabled />
        </el-form-item>
        <el-form-item :label="$t('Email')">
          <el-input clearable v-model="userEditForm.email" :placeholder="$t('Search by wallet')" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
        <span class="dialog-footer">
          <el-button
              class="btn btn-outline-secondary text-gray-600"
              @click="closePopup"
          >
            {{ $t('Close') }}
          </el-button>
          <el-button
              :disabled="this.userEditForm.email === this.initialEmail"
              class="btn btn-primary text-white"
              v-loading="userUpdateLoad"
              @click="handleUpdateUser"
          >
            {{ $t('Update') }}
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>
<script>
import store from "../../../store";
import NotificationService from "../../../core/services/NotificationService";
import { ERROR_STATUSES } from "../../../core/config/constants";

export default {
  name: "UserEditModal",
  props: {
    popupVisible: Boolean,
    userEditForm: Object
  },
  emits: ["handle-on-close", "handle-update-user"],
  mounted() {
    this.initialEmail = this.userEditForm.email
  },
  data() {
    return {
      initialEmail: '',
      isVisible: true,
      userUpdateLoad: false
    };
  },
  methods: {
    closePopup() {
      this.isVisible = false
    },
    handleOnClose() {
      this.$emit("handle-on-close");
    },
    async handleUpdateUser(){
      this.userUpdateLoad = true
      try {
        let res = await store.dispatch("updateUser", {
          id: this.userEditForm.id,
          email: this.userEditForm.email
        });
        if (res) {
          this.initialEmail = this.userEditForm.email
          NotificationService.swalNotify({ title: this.$t('Email has successfully updated')})
          this.$emit("handle-update-user", {
            id: this.userEditForm.id,
            email: this.userEditForm.email
          });
        }
      } catch (err) {
        if (err.response.data.code === ERROR_STATUSES.EXISTING_EMAIL){
          NotificationService.swalError(this.$t('Email is not unique'))
        }else {
          NotificationService.swalError(this.$t('User updating error'))
          console.error(err)
        }
      } finally {
        this.userUpdateLoad = false
      }
    },
  },
};
</script>

<style lang="scss">
  .custom-dialog {
    margin: 70px auto;
  }
</style>
