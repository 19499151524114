<template>
    <div class="d-flex">
        <slot></slot>
        <div class="ms-2">
            <img v-if="!timer"
                 :src="icon"
                 class="pointer"
                 @click="copy()" />
            <img v-else
                 :src="iconCopied"/>
        </div>
    </div>
</template>

<script>

export default {
    name: "ClipboardCopier",
    emits: ['onCopy', 'onCanCopy'],
    props: {
        value: {
            type: String,
            required: true,
        },
        copyTimeout: {
            type: Number,
            default: 2000,
        },
        initialTimeout: {
            type: Number,
            default: null,
        },
        icon: {
            default: require('../assets/images/icons/copy-blue.svg'),
        },
        iconCopied: {
            default: require('../assets/images/icons/upgraded.svg'),
        },
    },
    data () {
        return {
            timer: null,
        }
    },
    mounted() {
        if(this.initialTimeout) {
            this.setCopyTimeout(this.initialTimeout);
        }
    },
    methods: {
        clearTimer() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
        },
        setCopyTimeout(timer) {
            this.clearTimer();
            this.timer = setTimeout(() => {
                this.timer = null;
                this.$emit('onCanCopy');
            }, timer);
        },
        copy() {
            this.copyToClipboard();
            this.setCopyTimeout(this.copyTimeout);
            this.$emit('onCopy');
        },

        copyToClipboard() {
            if (this.value) {
              const textArea = document.createElement("textarea");
              textArea.value = this.value || "";
              document.body.appendChild(textArea);
              textArea.focus();
              textArea.select();

              try {
                document.execCommand("copy");
              } catch (err) {
                console.error("Failed to copy text: ", err);
              }

              document.body.removeChild(textArea);
            }
        }
    }
}
</script>