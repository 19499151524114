import {ERROR_MESSAGES, THROW_ERROR} from "@/core/config/constants";

export default class UserContainer {
  users = {} as Map<number, UserContainerItem>;

  constructor(data: any[]) {
    data.map(dt => this.users[dt.id] = new UserContainerItem(dt));
  }

  getUsers(): UserContainerItem[] {
    return Object.values(this.users).reverse();
  }

  updateEmail(userId: number, email: string): void {
    let item = this.users[userId];
    if (item) {
      item.email = email
    } else {
      THROW_ERROR(ERROR_MESSAGES.ITEM_NOT_FOUND);
    }
  }

}

class UserContainerItem {
  id: number;
  email: string;
  username: string | null;
  wallet_address: string;
  is_fully_web2: boolean;
  first_name: string;
  last_name: string;
  last_login_time: Date;
  notification_type: any;

  constructor(obj: any) {
    this.id = obj.id;
    this.email = obj.email;
    this.username = obj.username;
    this.wallet_address = obj.wallet_address;
    this.is_fully_web2 = obj.is_fully_web2;
    this.first_name = obj.first_name;
    this.last_name = obj.last_name;
    this.last_login_time = new Date(obj.last_login_time);
    this.notification_type = obj.notification_type;
  }
}
