<template>
  <el-dialog
    v-model="isVisible"
    width="500px"
    align-center
    @close="handleOnClose"
    :align-center="true"
  >
    <div class="text-center">
      <el-form :model="userUpdateRank" label-width="60px" class="demo-form-inline">
        <el-form-item :label="$t('Wallet')">
          <el-input v-model="userUpdateRank.walletAddress" disabled />
        </el-form-item>
        <el-form-item :label="$t('Rank')">
          <el-select v-model="userUpdateRank.rank" :placeholder="$t('Select status')">
            <el-option v-for="rank in Object.values(RankEnum)" :label="$t(rank)" :value="rank" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
        <span class="dialog-footer">
          <el-button
              class="btn btn-outline-secondary text-gray-600"
              @click="closePopup"
          >
            {{ $t('Close') }}
          </el-button>
          <el-button
              :disabled="this.userUpdateRank.rank === this.initialRank"
              class="btn btn-primary text-white"
              v-loading="updateRankLoad"
              @click="handleUpdateUser"
          >
            {{ $t('Update') }}
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>
<script>
import store from "../../../store";
import NotificationService from "../../../core/services/NotificationService";
import { RankEnum } from "../../../store/enums/RankEnum";

export default {
  name: "UserUpdateRank",
  props: {
    popupVisible: Boolean,
    userUpdateRank: Object
  },
  emits: ["handle-on-close", "handle-on-rank-update"],
  mounted() {
    this.initialRank = this.userUpdateRank.rank
  },
  data() {
    return {
      initialRank: '',
      isVisible: true,
      updateRankLoad: false,
      RankEnum
    };
  },
  methods: {
    closePopup() {
      this.isVisible = false
    },
    handleOnClose() {
      this.$emit("handle-on-close");
    },
    async handleUpdateUser(){
      this.updateRankLoad = true
      try {
        const { walletAddress, rank } = this.userUpdateRank
        await store.dispatch("binaryUserUpdateRank", {
          walletAddress,
          rank,
        });
        this.initialRank = rank
        this.$emit("handle-on-rank-update", rank);
        NotificationService.swalNotify({ title: this.$t('Rank has successfully updated')})
      } catch (err) {
          this.userUpdateRank.rank = this.initialRank
          NotificationService.swalError(this.$t('User rank updating error'))
          console.error(err)
      } finally {
        this.updateRankLoad = false
      }
    },
  },
};
</script>
